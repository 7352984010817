import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import Leader1Top from '../../../image/interviews/leader1_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function LeaderInterview1(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
    const theme = createTheme({
      typography: {
        fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
      },
    });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/discussion"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"T.Y"}
        entryYear={"2022"}
        title={isMobileScreen ? "めざしていたリーダーに、\nキャリア3年で到達。" : "めざしていた\nリーダーに、\nキャリア3年で到達。"}
        imageSrcTop={Leader1Top}
        text1={"\n――T.Yさんは新卒からキャリア3年でリーダーに抜擢ということで、社内でも大いに期待されています。ご自身ではどこが評価されたと思いますか？"}
        text2={"　コラボテクノには2022年度の新卒として入社しましたが、前年の2021年度の途中からエンジニア見習いのような感じで働いていました。なので、早期にリーダーになれたのは、同期より早くスタートしたからだと思います。ただ、まずはエンジニアとして一人前のスキルを備えたいと頑張りながら、いずれ会社組織のリーダーになりたいという思いは最初から持っていました。日々のエンジニア業務の傍ら、会社の社内行事や採用イベントなどに関与するうちにその思いはどんどん強くなり、上司にも伝えていました。こうしてキャリア3年でリーダーに任命してもらえたのは、自分ではスキルうんぬんではなく、熱意が通じてのことのような気がしています。"}
        text3={"\n――念願のリーダーになって、しばらく経ちました。実際に務めてみて、難しさはありましたか？　理想のリーダーのあり方についても教えてください。"}
        text4={"　現在、中堅のエンジニア2名と若手5名ほどのチームを率いています。実際のところ、やはり自分よりキャリアのあるメンバーとのやりとりは、相手のほうが知識も経験もあるので、難しく感じる時もあります。でも、素直にわからないことは聞いて、自分の意見はちゃんと伝えて、わかりあえる関係を構築したいです。また、リーダーは現場と会社とのつなぎ役なので、進捗の報告や連絡、会議の出席などが増えました。社長や営業部との意見交換もあり、なるべく気後れせず、率直な意見を言おうと心がけています。メンバーからの会社への意見はしっかりと吸収し、会社からメンバーに伝えたいことは漏らさず伝える、そんなリーダーをめざします。"}
        text5={"\n――組織内で出世するというのは、世間一般のエンジニア像と少し異なります。T.Yさんの今後のキャリアイメージを教えてください。"}
        text6={"　エンジニアのキャリアパスは、プログラミングや実装のスキルを磨いて独り立ちするイメージがありますが、現場でITビジネスの仕組みを学び、組織の一員として事業や組織運営に携わっていく方向性もあります。その場合、ゴールは企業のCTOやCEOとなるでしょう。今はどんな企業も、ITのスキルと経験があり、現場の技術職と渡り合える人材を探していると聞きます。これからエンジニア職にチャレンジすることは、自分の将来に広がりのある選択なのではないでしょうか。私は今、会社にすでにあったリーダーという役割を担っていますが、会社が大きく成長していく中で、リーダーを統括する新たな役割であったり、まったく異なる役割や枠組みが必要となった時に、それを担えるように自身も成長していきたいと考えています。また、経営や組織を学び続け、自ら新しい役割を生み出すような働きもできたらいいなと思っています。今後はエンジニアのスキルをベースに、会社や後輩の成長に貢献する自分になりたいですね。"}
      />
    </ThemeProvider>
  );
}