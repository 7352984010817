import { autocompleteClasses, Box, Typography, Grid } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import Tag from './Tag';

export default function ContentsBoxWithTag(children) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        position: 'relative',
        whiteSpace: 'pre-line',
      }}>
        <Tag tagStyle={children.tagStyle} tagText={children.tagText} />
        <Grid container Spacing={1} >
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography sx={{ fontSize: { xs: "20px", sm: "20px", md: "30px", lg: "2vw", xl: "2vw" }, textAlign: 'left', px: { xs: 3, sm: 1, md: 1, xl: 2 }, padding: "2% 0 0 0", color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>
              {children.title}
            </Typography>
            <Typography sx={{ fontSize: { xs: "20px", sm: "20px", md: "30px", lg: "2vw", xl: "2vw" }, textAlign: 'left', px: { xs: 3, sm: 1, md: 1, xl: 2 }, padding: "3% 0 0 0", color: `${colorTheme.palette.darkGray.main}` }} fontWeight={'bold'}>
              {children.title2}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12}>
            <Typography sx={{ fontSize: { xs: "16px", sm: "17px", md: "25px", lg: "1.5vw", xl: "1.8vw" }, textAlign: 'left', px: { xs: 3, sm: 1, md: 1, xl: 2 }, padding: "2% 0 2% 0", color: `${colorTheme.palette.navy.main}` }}>
              {children.text}
            </Typography>
            <Typography sx={{ fontSize: { xs: "16px", sm: "17px", md: "25px", lg: "1.5vw", xl: "1.8vw" }, textAlign: 'left', px: { xs: 3, sm: 1, md: 1, xl: 2 }, padding: "2% 0 2% 0", color: `${colorTheme.palette.darkGray.main}` }}>
              {children.text2}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  )
}
