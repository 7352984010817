import * as React from 'react';
import { Container } from '@mui/system';
import { createTheme, ThemeProvider } from '@mui/material/styles';
  
    const theme = createTheme({
      typography: {
        fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
      },
    });
  
export default function ContentsImage(props) {
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <img
                    src={process.env.PUBLIC_URL+props.imageSrc}
                    width={props.width}
                    height={props.height}
                    alt={props.alt}
                    style={{
                        borderRadius: "10px",
                    }}
                >
                </img>
            </Container>
        </ThemeProvider>
    );
  }