import { Box, Typography, ThemeProvider } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import MidcareerRecruitmentImg from "../image/web-photo-06.webp";
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";
import ContentsBox from "../components/ContentsBox";
import Slider from "../components/Slider";
import ContentsBoxWithBottomAndImage from "../components/ContentsBoxWithBottomAndImage";
import MidcareerImg from "../image/IMG_0819.webp";
import Tag from "../components/Tag";
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { createTheme } from '@mui/material/styles';

export default function MidcareerRecruitment(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Box className="App">
        <Box sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
          <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
            <ThemeProvider theme={colorTheme}>
              <div style={{ position: 'relative', width: '100%', padding: '0px 0px 45% 0px' }}>
                <img src={process.env.PUBLIC_URL + MidcareerRecruitmentImg} width='70%' height='100%' alt='トップ画像' style={{ position: 'absolute', right: 0 }}></img>
                <div style={{
                  display: 'inline-block',
                  width: '35%',
                  minWidth: '250px',
                  height: '80%',
                  backgroundColor: 'white',
                  position: 'absolute',
                  left: 0,
                  top: 180,
                  borderRadius: '0px 20px 20px 0px',
                  whiteSpace: 'pre-line',
                  margin: '-140px 0 -0px 0',
                }}>
                  <br />
                  <Tag tagStyle={'left'} tagText={"Current Openings"} />
                  <br />
                  <Box style={{
                    padding: '15px 5px 0px 50px',
                    textAlign: 'left'
                  }}>
                    <Typography variant="h5" sx={{
                      padding: '0 0 5% 0', textAlign: 'left', fontWeight: 'bold', fontSize: { xs: "28px", sm: "29px", md: "30px", lg: "2.5vw", xl: "2.8vw" }
                      , color: colorTheme.palette.navy.main
                    }}>
                      {"\n募集職種"}
                    </Typography>
                    <Typography variant="h9" sx={{ padding: '40px 0px', textAlign: 'left', fontSize: { xs: "16px", sm: "20px", md: "25px", lg: "30px", xl: "35px" }, lineHeight: '200%', color: colorTheme.palette.navy.main }}>
                      {'経験豊富なエンジニアから\n未経験の方まで\nコラボテクノのVISION/MISSIONに\n共感する方のチャレンジを\nお待ちしています。'}
                    </Typography>
                  </Box>
                </div>
              </div>
            </ThemeProvider>
          </Box>
          <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
            <img src={process.env.PUBLIC_URL + MidcareerRecruitmentImg} style={{ width: '100%' }} alt='新卒採用Top画像'></img>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: `${colorTheme.palette.creamGray.main}`,
          }}
        >
          <Box sx={{ display: { xs: "block", sm: "none" }, padding: "5% 0 5% 0", color: `${colorTheme.palette.navy.main}` }}>
            <ContentsBoxWithTag
              tagStyle={{ float: "left" }}
              tagText={"Current Openings"}
              title={"募集職種"}
              text={
                "経験豊富なエンジニアから未経験の方まで、\nコラボテクノのVISION/MISSIONに共感する方の\nチャレンジをお待ちしています。"
              }
            />
          </Box>
        </Box>
        <Box
          sx={{
            padding: "5% 0 0 0",
            backgroundColor: { xs: "white", sm: `${colorTheme.palette.creamGray.main}` },
          }}
        >
          <Box sx={{ position: "absolute", padding: "4% 0 0 0" }}>
            <Tag tagStyle={"left"} tagText={"中途採用情報"}></Tag>
          </Box>
          <Box
            sx={{
              width: "90%",
              padding: "0 0 5% 0",
              backgroundColor: "white",
              borderRadius: '0px 20px 20px 0px'
            }}
          >
            <Box sx={{ width: "95%" }}>
              <ContentsBoxWithBottomAndImage
                url={"/recruit/Mid-entry"}
                buttonText={"現在の求人情報"}
                tagStyle={"left"}
                imageSrc={MidcareerImg}
                title2={"自分のスキルとノウハウで\n自分らしく働きたい人へ。"}
                text1={
                  "コラボテクノではWeb系/オープン系のシステムエンジニアやPM/PL候補など幅広く募集しています。"
                }
              ></ContentsBoxWithBottomAndImage>
            </Box>
          </Box>
          <Box
            sx={{
              padding: "5% 5% 0 0",
              backgroundColor: { xs: "white", sm: `${colorTheme.palette.creamGray.main}` },
            }}
          >
          </Box>
          <Box sx={{ marginTop: "5%", padding: "5% 0 5% 0", backgroundColor: { xs: "white", sm: `white` } }}>
            <ContentsBox
              title={"中途入社で活躍する\n社員の声"}
              text={
                "中途入社した先輩社員に、\n転職の経緯や現在の働き方、\n今後の目標などをインタビューしました。"
              }
            ></ContentsBox>
          </Box>
          <Box sx={{ padding: "5% 0 5% 0", backgroundColor: { xs: "white", sm: `white` } }}>
            <Slider category="jobChange" slidesToShow={2}></Slider>
          </Box>
          {/* <Box
          sx={{
            width: "100%",
            marginTop: "5%",
            padding: "5% 0 5% 0",
            backgroundColor: { xs: "white", sm: `${colorTheme.palette.creamGray.main}` },
          }}
        >
          <Box sx={{ backgroundColor: "white" }}>
            <Box sx={{ position: "absolute", padding: "5% 0 0 0" }}>
              <Tag tagStyle={"left"} tagText={"新卒採用情報"}></Tag>
            </Box>
            <Box sx={{ width: "90%" }}>
              <ContentsBoxWithBottomAndImage
              url={"/recruit/mid-career/entry"}
              buttonText={"募集要項へ"}
                tagStyle={"left"}
                imageSrc={NewGraduateImg}
                title={"はじめの一歩を\nしっかり応援"}
                text={
                  "コラボテクノはスキルを身につけ、自分で歩もうとする新卒の方のチャレンジをお待ちしています。"
                }
              ></ContentsBoxWithBottomAndImage>
            </Box>
          </Box>
        </Box> */}
        </Box>
        <ScrollToTopIcon />
      </Box >
    </ThemeProvider>
  );
}
