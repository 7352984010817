import * as React from 'react';
import InterviewWithOnlyImage from './InterviewWithOnlyImage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
  
export default function PersonInterview(props) {
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <InterviewWithOnlyImage
        nextUrl={props.nextUrl}
        tagStyle={props.tagStyle}
        tagText={props.tagText}
        interviewee={`${props.name}\nシステムエンジニア ${props.entryYear}入社`}
        title={props.title}
        imageSrcTop={props.imageSrcTop}
        text1={props.text1}
        imageSrcText1={props.imageSrcText1}
        text2Head={props.text2Head}
        text2Body={props.text2Body}
        imageSrcText2={props.imageSrcText2}
        text3Head={props.text3Head}
        text3Body={props.text3Body}
        imageSrcText3={props.imageSrcText3}
      >
      </InterviewWithOnlyImage>
    </ThemeProvider>
  )
}
