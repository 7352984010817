import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee4Top from '../../../image/interviews/new_employee4_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/interview5"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"K.K"}
        entryYear={"2024"}
        title={isMobileScreen ? "エンジニアは、\n努力がきちんと報われる職業。" : "エンジニアは、\n努力がきちんと\n報われる職業。"}
        imageSrcTop={NewEmployee4Top}
        text1={"\n――大学では農業を学んでいたそうですが、なぜエンジニア職に就いたのですか？　エンジニアに関心を持ったきっかけや就職の経緯を教えてください。"}
        text2={"大学で「農業にITを生かす」というテーマの講義があり、ブロックで農業用ロボットをつくって動かしたりするなかで、エンジニアという職を知って興味が湧いたのがきっかけです。ただ、いざ就職活動となると、周りにIT志望の友人や相談相手がいなかったので、本当にエンジニア職に向いているのか、どんな会社が自分に合うのかなど不安も多くありました。噂されるように、ブラックな働き方なのかな、とか。でも、最終的には、エンジニアをやってみて合わないと感じたらその時に考えようと割り切って、この業界に飛び込みました。"}
        text3={"\n――確かに未知の分野への挑戦は怖さもありますよね。実際にコラボテクノに入社してみて、どうでしょう。就職前の不安は解消されましたか？"}
        text4={"入社してみて感じたのは、エンジニア職は思っていた以上に大変だということです。もちろん、どの職業に就いたとしても最初は大変なのでしょうが、エンジニアはとくにITの知識が必要なので、学ぶことが多いです。でも、その学んだ知識がすぐに仕事に生きていくので、達成感や成長の実感が得られて、やりがいがあります。こうして大変ではあるものの、いい感じに新社会人をスタートできたのは、コラボテクノだからなのかもしれません。就活中に聞いていた社員第一の社風は、入社するとまったくその通りで、新卒の自分のこともよく見てくれますし、先輩方も気さくに相談に乗ってくれます。今はもう不安はありません。"}
        text5={"\n――新卒から入社したK.Kさんの思う、エンジニア職のいいところはどんなところでしょうか？　学生さんや転職を考えている方におすすめポイントを教えてください。"}
        text6={"まだ一年目なので偉そうなことは言えませんが、エンジニアのいいところは、自分の頑張り次第で現状や未来を変えられるところだと思います。たとえば、学生の頃アルバイトで工場に勤めていた時は、決められた単純作業を繰り返すばかりで、ただ時間が経つのを待つだけでした。それと比べると、エンジニアの仕事は日々新たに学ぶことがあり、頑張ることで自分を高められます。徐々にスキルやノウハウが身につき、自分で今後の人生を変えられるような実感があります。努力がきちんと報われる仕事って意外と少ないと思うので、コツコツ積み重ねることが苦にならない人には、とくにおすすめの職業だと思います。"}
      />
    </ThemeProvider>
  );
}