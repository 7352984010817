import * as React from "react";
import SalesRepEntry from "./SalesRepEntry";

export default function Entry(props) {
  return (
    <div
    style={{
      fontFamily: "Arial, sans-serif",
      padding: "32px",
      maxWidth: "900px",
      margin: "0 auto",
      backgroundColor: "#ECECEA",
      borderRadius: "16px",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    }}
    >
      <SalesRepEntry></SalesRepEntry>
    </div>
  );
};