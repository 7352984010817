import * as React from "react";
import MuiButton from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { colorTheme } from "../const";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export default function Button(props) {
  const handleClick = () => {
    if (props.target === "_blank") {
      window.open(props.url, "_blank");
    } else {
      window.location.href = props.url; // 同じタブで開く
    }
  };

  return (
    <ThemeProvider theme={colorTheme}>
      <MuiButton
        variant="contained"
        color="turquoiseBlue"
        sx={{
          fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px" },
          letterSpacing: "0.05em",
          color: "white",
          borderRadius: "8px",
          width: { xs: "90%", sm: "300px", md: "350px" },
          padding: "12px 24px",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
          margin: "16px auto",
        }}
        onClick={handleClick}
      >
        {props.text}
        <ArrowForwardIosIcon sx={{ fontSize: "18px" }} />
      </MuiButton>
    </ThemeProvider>
  );
}
