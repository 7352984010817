import { createTheme } from "@mui/material";

export const colorTheme = createTheme({
  palette: {
    turquoiseBlue: {
      main: "#21BECE"
    },
    blackGray: {
      main: "#312F30"
    },
    lightGray: {
      main: "#D1D2D4"
    },
    creamGray: {
      main: "#ECECEA"
    },
    darkGray: {
      main: "#404040"
    },
    navy: {
      main: "#033866"
    }
  },
  typography: {
    fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
  },
})