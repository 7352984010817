import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import WorkLifeBalance3Top from '../../../image/interviews/work_life_balance3_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function WorkLifeBalanceInterview3(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
    const theme = createTheme({
      typography: {
        fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
      },
    });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/job-change-interview1"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"K.F"}
        entryYear={"2022"}
        title={isMobileScreen ? "子育てと両立しながら、\n楽しくフルタイム勤務。" : "子育てと両立\nしながら、楽しく\nフルタイム勤務。"}
        imageSrcTop={WorkLifeBalance3Top}
        text1={"\n――K.Fさんは出産を機に前の会社を辞められて、最近また働き始めたと聞きました。改めて退職や再就職の経緯を教えてください。"}
        text2={"　前の会社ではエンジニアとして7年くらい働いていました。新卒で入社し、結婚してからもしばらく続けて、産休・育休を取得。その後一旦、職場復帰したのですが、想像以上に仕事と家事・子育てがうまく回りませんでした。先進的なイメージのあるIT業界ですが、当時はまだ上長や同僚の理解も乏しく、子育てとの両立は難しかったです。「イクメン」なんて言葉も世の中に浸透していない頃で、夫からの協力も期待できず…。もう、毎日がいっぱいいっぱいで、仕事も楽しめなくなっていました。結局、仕事はいつでも再開できるから、子どもが手のかかるうちは専業主婦になろう、と決めました。"}
        text3={"\n――なるほど、もともと復帰前提だったのですね。その後、子育てがひと段落して再就職を考えた時、どのような不安がありましたか？"}
        text4={"　技術が日々進化する業界でブランクが長かったので、大きく変わっている今の技術についていけるのかな、という漠然とした不安はありました。でも、どちらかというと仕事の面よりも、家事・育児に対する不安のほうが大きかったです。一度つまずいているので、とにかく前の轍を踏まないように、なあなあだった家事分担を細かく振り分け、徹底しました。夫が担当の家事をしていなくても、絶対に手は出しません。一度でもやってあげると味を占めてしまうので、厳しい態度で臨んでいます（笑）。やっぱり共働きはお互いの協力が不可欠ですし、夫は以前と比べて、かなり理解してくれていると感じます。子どもは学校の宿題で私の仕事のことを書くなど応援してくれているようで、ホッとしました。"}
        text5={"\n――子育てとの両立には、“夫育て”が重要かもしれないですね。他にも、K.Fさんのように専業主婦から再就職をめざす方にアドバイスをいただけたら。"}
        text6={"　他に工夫した点でいうと、思い切ってロボット掃除機や、いい洗濯機を導入しました。めんどうな家事は、相手ではなく、機械に頼る。そうすると、家事負担の不公平感も薄れますし、単純にラクですね。我が家は家事の機械化と効率化を推進していきます（笑）。今の専業主婦の皆さんは、何らかの事情で、働きたいのに働けないのではと思います。私の場合、正社員フルタイム勤務といっても、以前と違って在宅ワークなので、とても両立しやすくなりました。子どもに何かあっても家からすぐに駆けつけられるし、子ども一人で留守番させることもありません。もしも働けない事情が子育てなら、リモート主体のエンジニアはおすすめの職業です。元エンジニアの主婦の方も、これからエンジニアになる主婦の方も、一緒に楽しく仕事しましょう！"}
      />
    </ThemeProvider>
  );
}