import * as React from "react";
import DropdownSection from "../components/DropdownSection";
import DropdownItem from "../components/DropdownItem";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle) {
      document.title = `${props.pageTitle}｜コラボテクノ株式会社`;
    }
  }, [props.pageTitle]); // ← 空の依存配列を追加

  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          padding: "32px",
          maxWidth: "900px",
          margin: "0 auto",
          borderRadius: "16px",
        }}
      >
        <DropdownSection 
          title={"経験浅システムエンジニア"}
          buttonText="応募する"
          buttonUrl="https://forms.gle/nWpW7iaq6XpuzPfR9"
        >
        <div style={{ fontSize: "18px", color: "#333", marginBottom: "24px" }}>
          求人の詳細は下記の各項目をクリックしてご確認ください。
        </div>
        <DropdownItem
          number="01"
          title="募集背景"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>当社「コラボテクノ」では、現在、大手のSierに対し、</li>
                <li>WEB開発、クラウド基盤構築を中心にしたSES事業を中心にいますが、</li>
                <li>今後これに加え、企業のIT化支援（DX推進やITコンサルティング）を展開したいと考えおり</li>
                <li>今後の事業の仲間になっていただける方を積極的に募集します。</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="02"
          title="業務概要"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>【業務内容】</li>
                <li>要件定義～基本設計、開発、保守運用の業務に携わっていただきます。</li>
                <li>案件はご経験・ご希望により決定します。</li>
                <li>【入社後のイメージ】</li>
                <li>アサインされた開発案件を担当いただきます。</li>
                <li>ご経験に応じて開発業務遂行において必要となる</li>
                <li>要件確認、設計および開発や社内でのリソース調整等をお任せします。</li>
              </ul>
            </div>
          }
          />
        <DropdownItem
          number="03"
          title="開発プロジェクトの実績"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■通信キャリア向け顧客対応システム構築</h3>
              <li>WEB、店頭、コールセンター向けの情報管理システム</li>
              <li>サーバーサイドは</li>
              <li>Kotlin、Java、Springboot ,Vue、React、インフラは、マイクロサービス、AWS</li>
              <br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■ポイントアプリのカード券面の着せ替えアプリ開発</h3>
              <li>生産性を上げるためAIを使って設計書～テスト項目書を作成</li>
              <li>AIは、Claude 3.5 Sonnetを使用</li>
              <br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■大手SNSサイト広告審査システム</h3>
              <li>大手SNSサイトに掲載される広告の可否を審査するシステム</li>
              <li>サーバーサイドは　Java、Springboot</li>
              <li>フロントは　Angular</li>
              <br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■医療メーカー 販売管理システムの刷新</h3>
              <li>ワークフローシステムをFlowLitesから</li>
              <li>Microsoft Power Appsを使用し、新システムに刷新</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="05"
          title="応募資格・条件"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>＜経験・スキル＞</li>
                <li>【必須スキル】</li>
                <li>■1～3年程度のシステム開発またはIT業務経験</li>
                <li>■チームワークを大切にし、成長に対する強いモチベーションがある方</li>
                <li>■社内イベントにも積極的に参加出来る方</li>
                <li>■経験のないスキルでも向上心を持って取り組める方</li>
                <br/>
                <li>＜あると望ましいスキル＞</li>
                <li>■Java、Python、JavaScriptなどのプログラミング経験</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="06"
          title="募集人数"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>若干名</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="07"
          title="勤務形態"
          subtitle=""
          content={
            <div>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務地】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>東京都港区芝浦3丁目17-11天翔田町ビル1002号</li>
                <li>(自社内で開発を行う場合と、顧客先で開発を行う場合があります)</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務時間】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>完全土日祝休み 9:30 ～ 18:30</li>
                <li>（顧客先により異なる場合があります。）</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【休日休暇】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 年間休日120日以上</li>
                <li>◇ 夏季休暇</li>
                <li>◇ 年末年始休暇</li>
                <li>◇ 産前産後休暇・育児休暇制度</li>
              </ul><br/>
            </div>
          }
        />
        <DropdownItem
          number="08"
          title="福利厚生・待遇"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 雇用保険</li>
                <li>◇ 厚生年金</li>
                <li>◇ 労災保険</li>
                <li>◇ 健康保険</li>
                <li>◇ 交通費支給あり</li>
                <li>◇ テレワーク・在宅OK</li>
                <li>◇ 時短勤務制度あり</li>
                <li>◇ 資格取得支援（受験料・書籍購入・メンター相談）</li>
                <li>◇ チーム懇親会費用負担</li>
                <li>◇ 技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能</li>
                <li>◇ フリーワーク制度（社内副業）</li>
                <li>◇ アルムナイ採用・リファラル採用積極的に受け入れ中</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="09"
          title="給与"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>年俸　3,500,000円　～　5,500,000円</li>
                <li>(入社当初のスキルによって変動あり)</li>
                <li>【給与詳細】</li>
                <li>・30時間超過分は別途支給</li>
                <li>・別途業績に応じた賞与を支給（年1回）</li>
                <li>・ミッショングレード制をテスト施行中</li>
                <li>（年功序列ではなく、スキルレベルに応じた給料体系となっております。）</li>
                <li>・昇給は年１回です。</li>
                <li>（人事考課制度1回、四半期ごとの振り返りでの目標管理をしております。）</li>
              </ul>
            </div>
          }
        />
      </DropdownSection>
    </div>
    </ThemeProvider>
  );
};
