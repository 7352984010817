import * as React from 'react';
import { autocompleteClasses, Typography } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
import { colorTheme } from '../const';
import { createTheme, ThemeProvider } from '@mui/material/styles';
  
export default function ContentsBoxWithSideLine(children) {
  
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })

  return (
    <ThemeProvider theme={theme}>
      <div style={{
        textAlign: 'left',
        justifyContent: 'space-between',
        flexDirection: 'column',
        whiteSpace: 'pre',
        display: isMobileScreen ? 'flex' : children.display,
        width: isMobileScreen ? autocompleteClasses : '40%',
        padding: isMobileScreen ? '5% 11%' : children.padding,
        left: children.left,
        right: children.right
      }}>
        <Typography fontSize={isMobileScreen ? '5.5vw' : '2.3vw'} letterSpacing={isMobileScreen ? '1px' : '3px'} padding={isMobileScreen ? '10px 0 8px 10px' : '2% 0 5% 4%'} borderLeft={isMobileScreen ? 'thick solid #033866' : 'solid 8px #033866'} style={{ lineHeight: '1.0em', color: colorTheme.palette.navy.main }} sx={children.titleStyle} fontWeight={'bold'}>
          {children.title}<span style={{ fontSize: isMobileScreen ? '4vw' : '1.5vw' }}>{children.subtitle}</span>
        </Typography>
        <Typography fontSize={isMobileScreen ? '3vw' : '1.4vw'} letterSpacing='1px' sx={children.textStyle} padding={isMobileScreen ? '0px 0px 0px 10px' : '0px 0px 2% 4%'} borderLeft={isMobileScreen ? 'thick solid #033866' : 'solid 8px #033866'} style={{ color: colorTheme.palette.darkGray.main }}>
          {children.text}
        </Typography>
      </div>
    </ThemeProvider>
  );
}