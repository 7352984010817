import { autocompleteClasses, Box, Typography } from '@mui/material';
import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ContentsImage from './ContentsImage';
import Tag from './Tag';

export default function ContentsBoxWithTagAndIcon(children) {
  return (
    <ThemeProvider theme={colorTheme}>
      <Box sx={{
        width: autocompleteClasses,
        height: autocompleteClasses,
        backgroundColor: 'inherit',
        position: 'relative',
        whiteSpace: 'pre-line',
      }}>
        <Tag tagStyle={children.tagStyle} tagText={children.tagText} ></Tag>
        <Box sx={{padding:"15% 40% 0 20% "}}>
          <ContentsImage imageSrc={children.imageSrc} width="200%" alt=""></ContentsImage>
        </Box>
        <br></br>
        <Box style={{ width: "100%", textAlign: 'center' }}>
          <Typography variant="h5" sx={{ fontSize: { xs: "40px", sm: "40px", md: "30px", lg: "80px", xl: "80px" } }} style={{ textAlign: 'center', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{children.title}</Typography>
          <Typography variant="h5" sx={{ fontSize: { xs: "20px", sm: "20px", md: "15px", lg: "40px", xl: "40px" } }} style={{ textAlign: 'center', color: `${colorTheme.palette.navy.main}`, }} fontWeight={'bold'}>{children.subtitle}</Typography>      
          <Typography variant="h5" sx={{ fontSize: { xs: "4vw", sm: "2vw", md: "2vw", lg: "2vw", xl: "2vw" } }} style={{ padding: '3% 0px 2% 5%', textAlign: 'left', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{children.text}</Typography>
          <Typography variant="h2" sx={{ fontSize: { xs: "4vw", sm: "2vw", md: "2vw", lg: "2vw", xl: "2vw" } }} style={{ padding: '0% 0% 2% 3%', textAlign: 'left', color: `${colorTheme.palette.navy.main}` }} fontWeight={'bold'}>{children.suppText}</Typography>
        </Box>
      </Box>
    </ThemeProvider>

  )
}
