import * as React from 'react';
import MuiButton from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {HashLink} from 'react-router-hash-link';

export default function ButtonCircle(props) {
  return (
    <ThemeProvider theme={colorTheme}>
      <HashLink to={props.url}>
      <MuiButton color='blackGray' sx={{
          backgroundColor: props.color,
          border: 'solid 2px',
          fontSize: { xs: "16px", sm: "18px", md: "20px", lg: "22px" },
          letterSpacing: "0.05em",
          color: "black",
          borderRadius: "8px",
          width: { xs: "90%", sm: "70%", md: "80%" },
          padding: "12px 24px",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "16px 20px",
        }}>
          <b>{props.text}</b>
          <ArrowForwardIosIcon sx={{ height: '15px' }} />
        </MuiButton>
      </HashLink>
    </ThemeProvider>
  );
}
