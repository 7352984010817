import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import JobChange2Top from '../../../image/interviews/job_change2_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function JobChangeInterview2(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/leader-interview1"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"Y.M"}
        entryYear={"2023"}
        title={isMobileScreen ? "転職で、プライベートのない\n激務から解放。" : "転職で、\nプライベートのない\n激務から解放。"}
        imageSrcTop={JobChange2Top}
        text1={"\n――前職は医療業界で、しかもMRという誰もが憧れる経歴です。どうして転職したのですか？　少しもったいない気もしますが…。"}
        text2={"　製薬会社のMR（医療情報担当者）は、病院の医師や関係者のもとを訪ねて、自社製品の特徴や他社との違いなどを説明し、処方していただくのが主な仕事です。患者さんの笑顔につながるというやりがいはあるものの、MRも営業職なので厳しいノルマがあり、朝早くから夜遅くまでプライベートの時間を削ってでも働く毎日でした。せっかくの休日も、疲れ切っているので、寝るだけで終わってしまう…。新卒入社から数年働いてみて、あと30年この働き方を続けられるだろうか、と限界を感じたので、思い切って転職することにしました。"}
        text3={"\n――確かに20代は激務に耐えられても、その先を考えると怖くなりますね。転職を意識した時はすでに、エンジニアに興味があったのですか？"}
        text4={"　正直に言うと、それまでIT業界やシステム構築に強い関心があったわけではありません。今までとまったく違う仕事をしてみたいという好奇心と、働く時間を自分主導で決められる、プライベートの時間をしっかり取れる仕事を探して、エンジニア職を見つけた感じです。自分なりに調べていくうちに、アマゾン・ウェブ・サービス（AWS）の存在を知って興味を持ったのですが、もちろん詳しい知識も技術もなかったので、まずはAWSのスクールに通いました。ここで学びながら、自分なりに手応えを感じ、インフラエンジニアとして働き始めて今に至ります。仕事は基本的に定時に終わるので、平日の夜も休日も自分の好きなように使えるのが嬉しいですね。前職と比べると天国というか、とても穏やかに過ごせています。"}
        text5={"\n――Y.Mさんは2023年にコラボテクノに入社しましたが、ここを選んだ理由とは？　また、異業種からのチャレンジを考えている方にメッセージをください。"}
        text6={"　コラボテクノは2社目になります。ここに決めたのは、自信がついてもう少し大きな仕事をしてみたかったのと、業界内でも社員のやりたいことをサポートしてくれる会社と評判が良かったからです。今はエンジニア100人規模の大きなプロジェクトに携わり、業務ごとに分かれたチームと連携しながら、業務を進めています。スムーズに作業を進めるためには、関係する方たちとのコミュニケーションやスケジュール調整が大切になってきますが、この辺りは前職のMR時代の経験が生きていると思います。異業種からの転職は不安があっても、やってみると案外うまくいくものです。これまで培ったことは何かと身を助けてくれますし、専門知識や技術はやる気さえあれば習得できます。とくにコラボテクノは親身になって教えてくれる人たちばかりなので、そこは安心してチャレンジしてもらえればと思います。"}
      />
    </ThemeProvider>
  );
}