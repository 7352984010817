import { Box, Typography, Grid } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tag from "../components/Tag";
import recruitNewEntry from '../image/entry/recruit_new_entry.webp';
import recruitMidEntry from '../image/entry/recruit_mid_entry.webp';
import recruitOthersEntry from '../image/entry/recruit_others_entry.webp';

function RecruitmentPage() {
  const data = [
    {
      title: '新卒採用',
      description: '高等専門学校および大学を卒業見込みの方、社会人経験満1年未満の方はこちらをご覧ください',
      link: 'recruit/new-entry',
      image: recruitNewEntry,
    },
    {
      title: 'エンジニア経験者採用',
      description: '社会人経験満1年以上の方はこちらをご覧ください',
      link: 'recruit/mid-entry',
      image: recruitMidEntry,
    },
    {
      title: 'その他募集',
      description: '詳細はこちらをご覧ください',
      link: 'recruit/sales-rep',
      image: recruitOthersEntry,
    },
  ];

  // 画面幅に応じて高さを調整
  const isMobile = window.innerWidth <= 480; // 画面幅480px以下をモバイルと判断
  const cardHeight = isMobile ? '250px' : '400px'; // モバイルでは300px、高解像度では700px

  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ backgroundColor: `${colorTheme.palette.creamGray.main}` }}>
        {/* 青タグとヘッダーの間の余白を追加 */}
        <Box
          sx={{
              margin: 0, // マージンを完全にリセット
              paddingTop: "2%", // 必要に応じて調整
              backgroundColor: `${colorTheme.palette.creamGray.main}`,
            }}
        >
          <Tag tagStyle={{ float: "left" }} tagText={"採用情報"} />
        </Box>
        <Grid container Spacing={1}>
          <Typography
            sx={{
              fontSize: { xs: "20px", sm: "30px", md: "30px", lg: "30px", xl: "2vw" },
              textAlign: 'left',
              margin: '0',
              px: { xs: 3, sm: 1, md: 1, xl: 2 },
              padding: { xs: "5% 0 0 5%", sm: "5% 0 0 5%", md: "5% 0 0 1%", lg: "1% 0 0 0.5%" },
              color: `${colorTheme.palette.navy.main}`
            }}
            fontWeight={'bold'}
          >
            現在募集中の求人一覧
          </Typography>
        </Grid>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
            gap: '20px',
            padding: '20px',
          }}
        >
          {data.map((item, index) => (
            <a
              key={index}
              href={item.link}
              style={{
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              <div
                style={{
                  marginTop: "3%",
                  marginBottom: "3%",
                  position: 'relative',
                  borderRadius: '8px',
                  overflow: 'hidden',
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                  height: cardHeight, // 画面幅に応じた高さ
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease', // スムーズなアニメーション
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'translateY(-10px)'; // 浮き上がる動き
                  e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.3)'; // 影を強調
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'translateY(0)'; // 元に戻る
                  e.currentTarget.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.2)'; // 元に戻る
                }}
              >
                {/* 画像の半透明背景を実現 */}
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${item.image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    filter: 'brightness(60%)',
                  }}
                ></div>

                {/* 左上に文字を配置 */}
                <div
                  style={{
                    position: 'absolute',
                    top: '10px', // 左上に配置
                    left: '10px',
                    zIndex: 1,
                    color: 'white',
                    padding: '10px',
                    borderRadius: '4px',
                    maxWidth: '90%',
                  }}
                >
                  <h3
                    style={{
                      margin: '0',
                      fontSize: { xs: "20px", sm: "30px", md: "30px", lg: "30px", xl: "2vw" },
                      color: "rgba(255, 255, 255, 1)"
                    }}
                  >
                    {item.title}
                  </h3>
                  {item.description && (
                    <p
                      style={{
                        margin: '5px 0 0',
                        fontSize: { xs: "20px", sm: "30px", md: "30px", lg: "30px", xl: "2vw" },
                        lineHeight: '1.4',
                        color: "rgba(255, 255, 255, 1)"
                      }}
                    >
                      {item.description}
                    </p>
                  )}
                </div>
              </div>
            </a>
          ))}
        </div>
      </Box>
    </ThemeProvider>
  );
}

export default RecruitmentPage;
