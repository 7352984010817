import React from "react";
import { Box } from "@mui/system";
import EntryButton from "./EntryButton";

const DropdownSection = ({ title, children, buttonText, buttonUrl }) => {
  const isMobile = window.innerWidth <= 768; // スマホ判定

  return (
    <div
      style={{
        marginBottom: "24px",
        backgroundColor: "#e0e7ff",
        borderRadius: "8px",
        border: "2px solid rgba(3, 56, 102, 1)",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <Box
        style={{
          width: "100%",
          backgroundColor: "rgba(3, 56, 102, 1)",
          color: "#ffffff",
          border: "none",
          fontSize: isMobile ? "18px" : "20px",
          fontWeight: "bold",
          cursor: "pointer",
          borderRadius: "8px 8px 0 0",
          transition: "background-color 0.3s, transform 0.2s",
          overflow: "hidden",
          padding: isMobile ? "11px" : "20px",
          lineHeight: "1.4",
          whiteSpace: "pre-wrap", // ← 改行を反映
          wordBreak: "break-word", // ← 長い単語で折り返し
          overflowWrap: "break-word", // ← さらに折り返しを強制
          display: "block", // ← 確実に適用
        }}
      >
        {title}
      </Box>
      <div
        style={{
          padding: "20px",
          backgroundColor: "#ffffff",
          color: "#555",
          fontSize: "18px",
          lineHeight: "1.6",
        }}
      >
        {children}
        <Box sx={{ marginTop: "24px", display: "flex", justifyContent: "center" }}>
          <EntryButton text={buttonText || "応募する"} url={buttonUrl || "#"} />
        </Box>
      </div>
    </div>
  );
};

export default DropdownSection;
