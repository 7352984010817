import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import WorkLifeBalance1Top from '../../../image/interviews/work_life_balance1_top.webp'
import WorkLifeBalance1Text1 from '../../../image/interviews/work_life_balance1_text1.webp'
import WorkLifeBalance1Text2 from '../../../image/interviews/work_life_balance1_text2.webp'
import WorkLifeBalance1Text3 from '../../../image/interviews/work_life_balance1_text3.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function WorkLifeBalanceInterview1(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
    const theme = createTheme({
      typography: {
        fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
      },
    });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview
        nextUrl={"/recruit/member/work-life-interview2"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"K.O"}
        entryYear={"2021"}
        title={isMobileScreen ? "結婚したことで、\n自分の仕事力がアップ。" : "結婚したことで、\n自分の仕事力\nがアップ。"}
        imageSrcTop={WorkLifeBalance1Top}
        text1={"私の場合、結婚前と後で、働き方が大きく変わったかといえば、実はほとんど変化がありません。夫とは地元が同じで、住まいこそそちらに引っ越しましたが、以前からリモート勤務していたので、パソコンの置き場が変わったくらい。ただ、1日の時間の使い方は、より意識するようになりました。仕事前や休憩時間にちょこっと家事をこなしたり、性格的にあまり後に残したくないので、合間にパッと。仕事自体も、限られた時間を有効に使う意識が高まり、以前に増して集中できています。\n\n共働きですが、夫は何でも自分でやるタイプで、私より早く起きて、自分でお弁当を作って出勤してしまう。夕飯も、仕事が早く終わったほうが作ると決めていて、今はだいたい夫がつくってくれます。掃除も、洗濯も…。結果的に、家事負担は夫の方が多いんじゃないかな。助かっています（笑）。"}
        imageSrcText1={WorkLifeBalance1Text1}
        text2Head={"仕事と家庭の両立は、\nチャレンジではなく、マストです。"}
        text2Body={"夫が在宅ではできない仕事をしているので、動ける私が引っ越しましたが、この先、世の中がリモート廃止の方向に進んだら、私は単身赴任します。夫にも伝えてあり、賛成してくれています。私は母がずっと働き続けていて、その姿を見てきたので、両立は難しいことだけど、できないことではないと知っています。だから、あとはどう実現するか、だけ。これからもライフステージの変化はいろいろあるでしょうが、夫婦で話し合って築き上げていこうと思います。\n\nエンジニアになったのは偶然というか、元々は語学を勉強していて、語学を活かした仕事、漠然とツアーガイドやホテル、CAとかを思っていました。ただ、就職を考え始めた時期に、コロナの影響で募集もなく、業界の先行きも不透明で、どうしようか、と。そんな時、留学先のシェアハウスのオーナーが元エンジニアの方で、いろいろと話すうちに、その気になったという感じです。在宅でも働ける、妻や母になっても続けられるとは、後で知りました。自分でも運命かなと思います（笑）。"}
        imageSrcText2={WorkLifeBalance1Text2}
        text3Head={"エンジニアは女性にとって、\nあきらめなくていい職業。"}
        text3Body={"知識、人間性で評価されるというのは、すごく感じます。それに、男女の差は出そうにも出せない仕事。問題をムキムキの腕力で解決するものでもないですし。女性だからとか、出産あるから育児あるからとかで、仕事も結婚もあきらめなくていい職業だと思います。一方で、現場が変わったら、知識もまったく違うものが求められるので、一生勉強が続くというシビアさもあります。\n\n女性の中でも、バリバリ仕事したいとか、どんどん自分でキャリアを積んでいきたいとか、そういう人にはすごくいいなって思うし、自分次第でどこまでもいけるっていう職業だと思うので。これからの女性にエンジニア、おすすめします。"}
        imageSrcText3={WorkLifeBalance1Text3} />
    </ThemeProvider>
  );
}