import * as React from "react";
import DropdownSection2Button from "../components/DropdownSection2Button";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle) {
      document.title = `${props.pageTitle}｜コラボテクノ株式会社`;
    }
  }, [props.pageTitle]); // ← 空の依存配列を追加
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "32px",
        maxWidth: "900px",
        margin: "0 auto",
        borderRadius: "16px",
      }}
    >
      <DropdownSection2Button 
        title="26卒向け選考直結型イベント"
        buttonText1="新卒採用情報はこちら"
        buttonUrl1="/recruit/new-graduate-entry"
        targetLink1="_self"
        buttonText2="応募する"
        buttonUrl2="https://forms.gle/aYs9yXQ7kgH88G516"
        targetLink2="_blank"
        
      >
        <h2 style={{ fontSize: "18px", fontWeight: "bold" , color:"red"}}>
          26卒向けのプログラミング体験イベントは全て終了しました。<br/>
          26卒向けの選考は下記のボタンよりご確認ください。
        </h2><br/>
        <h2 style={{ fontSize: "16px", fontWeight: "bold"}}> 
          【プログラミング体験】にご招待！
        </h2><br/>
        <div>
          　コラボテクノの若手エンジニアと一緒に<br/>
          　プログラミング体験をしよう！
        </div><br/>
        <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}> 
          <li>ゲーム「テトリス」作りを体験しよう</li>
          <li>・実施日程：</li>
          <li>　・2024年10月19日（土）、26日（土）</li>
          <li>　・2024年11月9日（土）、23日（土）</li>
          <li>　・2024年12月7日（土）</li>
          <li>　・2025年1月18日（土）</li>
          <li>・実施時間：</li>
          <li>　13:00～15:30</li>
          <li>　　プログラミング体験</li>
          <li>　15:30～16:30</li>
          <li>　　コラボテクノの会社説明およびエンジニアから現場説明</li>
          <li>・場所：</li>
          <li>　東京都港区新橋3-11-8 オーイズミ新橋第2ビル 901号</li>
          <li>・募集人員：</li>
          <li>　最大4名／1回</li>
          <li>・持ち物：</li>
          <li>　ご自身のPCを持参ください</li>
          <li>・服装：</li>
          <li>　自由</li>
        </ul>
      </DropdownSection2Button>
    </div>
    </ThemeProvider>
  );
};

