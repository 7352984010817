import React from "react";
import { Link } from 'react-router-dom';
import '../../App.css';
import '../../pages-css/corporate.css';
import FooterLogoImg from '../../image/company/ft-logo.webp';
import PrivacyMarkImg from '../../image/company/10824551_75_JP.gif';
import { Box, Grid } from '@mui/material';
import { Container } from '@mui/system';
import {HashLink} from 'react-router-hash-link';

const Footer = () => {
  return (
    <footer id="footer">
      <Box sx={{ flexGrow: 1 }} className="footer-inner">
        <Grid container>
          <Grid item xs={12} sm={2}>
            <Container maxWidth="sm" sx={{ textAlign: 'right', padding: '0 10px'}}>
              <div className="footlogo">
                <Link to="/"><img src={process.env.PUBLIC_URL+FooterLogoImg} className="footerlogo" alt="footerLogo"/></Link>
              </div>
            </Container>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Container maxWidth="sm" sx={{ textAlign: 'left'}}>
              <ul className="footerList">
                <li>
                  <Link to="/service" className="footerLinkCategory">サービス - SERVICE</Link>
                  <ul>
                    <li><HashLink to="/service#sec1">Webシステム開発</HashLink></li>
                    <li><HashLink to="/service#sec2">システムエンジニアリングサービス(SES)</HashLink></li>
                    <li><HashLink to="/service#sec3">開発領域</HashLink></li>
                    <li><HashLink to="/service#sec4">プロジェクト事例</HashLink></li>
                    <li><HashLink to="/service#sec5">フリーランスエンジニアの求人・案件情報【コラビィ】</HashLink></li>
                  </ul>
                </li>
              </ul>
            </Container>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Container maxWidth="sm" sx={{ textAlign: 'left'}}>
              <ul className="footerList">
                <li>
                  <Link to="/about" className="footerLinkCategory">会社情報 - ABOUT US</Link>
                  <ul>
                    <li><HashLink to="/about#sec1">会社概要</HashLink></li>
                    <li><HashLink to="/about#sec2">ビジョン・ミッション</HashLink></li>
                    <li><HashLink to="/about#sec3">提携会社(グループ会社)</HashLink></li>
                    <li><HashLink to="/about#sec4">アクセス</HashLink></li>
                    <li><a href="https://note.com/yoshida_colla" target="_blank" rel="noreferrer">社長ブログ</a></li>
                  </ul>
                </li>
              </ul>
            </Container>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Container maxWidth="sm" sx={{ textAlign: 'left'}}>
              <ul className="footerList">
                <li>
                  <Link to="/recruit" className="footerLinkCategory">採用情報 - RECRUIT</Link>
                  <ul>
                    <li><Link to="/recruit">採用コンセプト</Link></li>
                    <li><Link to="/recruit/new-graduates">新卒採用</Link></li>
                    <li><Link to="/recruit/mid-career">中途採用</Link></li>
                    <li><Link to="/contact">開発パートナー募集</Link></li>
                  </ul>
                </li>
              </ul>
            </Container>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Container maxWidth="sm" sx={{ textAlign: 'left'}}>
              <ul className="footerList">
                <li><Link to="/privacy" className="footerLinkCategory">PRIVACY POLICY</Link></li>
                <li><Link to="/contact" className="footerLinkCategory">お問い合わせ</Link></li>
              </ul>
            </Container>
          </Grid>
          <Grid item xs={12} sm={1}>
            <Container maxWidth="sm" sx={{ textAlign: 'left'}}>
                <a href="https://privacymark.jp/" className="privacymark">
                  <img src={PrivacyMarkImg} alt="privacymark"/>
                </a>
            </Container>
          </Grid>
        </Grid>
      </Box>
      <div id="copyright">©{new Date().getFullYear()} <Link to="/">CollaboTechno</Link> Co.,LTD</div>
    </footer>
  );
};

export default Footer;
