import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { colorTheme } from '../const';

export default function ContentsBox(children) {
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{
        width: { xs: "100%", sm: "100%", md: "100%", lg: "100%", xl: "100%" },
        backgroundColor: 'inherit',
        position: 'relative',
        whiteSpace: 'pre-line'
      }}>
        <Box style={{
          padding: children.isZeroPadding ? '0px' : '15px 45px 15px 45px',
          textAlign: 'left',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column'
        }}
        >
          <Typography  fontSize={{ xs: "20px", sm: "20px", md: "30px", lg:"2vw", xl: "2vw" }} style={{ padding: children.title, color: `${colorTheme.palette.darkGray.main}` }} sx={children.titleStyle} fontWeight={'bold'}>
            {children.title}
          </Typography>
          <Typography  fontSize={{ xs: "20px", sm: "20px", md: "30px", lg:"2vw", xl: "2vw" }} style={{ padding: children.title, color: `${colorTheme.palette.navy.main}` }} sx={children.titleStyle} fontWeight={'bold'}>
            {children.title2}
          </Typography>
          <Typography fontSize={{ xs: "16px", sm: "15px", md: "25px", lg:"1.5vw", xl: "1.8vw" }} style={{ padding: children.textStyle, color: `${colorTheme.palette.darkGray.main}`}} sx = {{paddingBottom : "2.2%"}}>
            {children.text}
          </Typography>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
