import * as React from 'react';
import '../../../App.css';
import PersonInterview from '../../../components/PersonInterview';
import WorkLifeBalance2Top from '../../../image/interviews/work_life_balance2_top.webp'
import WorkLifeBalance2Text1 from '../../../image/interviews/work_life_balance2_text1.webp'
import WorkLifeBalance2Text2 from '../../../image/interviews/work_life_balance2_text2.webp'
import WorkLifeBalance2Text3 from '../../../image/interviews/work_life_balance2_text3.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function WorkLifeBalanceInterview2(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
    const theme = createTheme({
      typography: {
        fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
      },
    });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview
        nextUrl={"/recruit/member/work-life-interview3"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"T.Y"}
        entryYear={"2016"}
        title={isMobileScreen ? "まるまる1ヶ月間、\n\"育業\"の現場にシフト。" : "まるまる1ヶ月間、\n\"育業\"の現場に\nシフト。"}
        imageSrcTop={WorkLifeBalance2Top}
        text1={"エンジニアじゃない知り合いからは、男性の育休取得は珍しがられますけど、この会社には先例があり、僕が2人目。しかも、ほとんど同じ時期に3人目も控えていて、全然レアケースじゃない。常駐しているシステム構築の現場の方も、「おめでとう！で、いつにする？」という感じ。タイミングも期間も、こちら任せでした。今動かしているのが長期のプロジェクトなので、仕事が比較的少なくなるタイミングで、まるまる1ヶ月間取らせてもらうことにしました。\n\nうちは共働きで朝食は妻、夕食は僕といった感じで、家事の分担は当たり前のようにやってきました。これからは育児に関しても、同じようにシェアしていけたら。東京都では“育業”と言い換えて、休みじゃないよ、ということなので、夫婦二人で頑張って育てたいと思います。すでに産まれていて、今は妻の実家にいるので、早く迎えにいきたいですね。初めのうちは大変でしょうけど、それすらも楽しみで仕方ない。イージーに育休を取れる、エンジニアになっていてよかったです（笑）。"}
        imageSrcText1={WorkLifeBalance2Text1}
        text2Head={"会社勤めも、フリーランスも経験して、\nコラボテクノへ。"}
        text2Body={"実は、僕は最初からエンジニアではなくて、外資系の大手製薬企業の営業、いわゆるMRをやっていました。正直、給料は良かったです。初年度からビックリするくらい貰えて。ただ、会社の先輩を見てて、自分が40代50代になった時に、この働き方できるかな、と感じてしまい、1年足らずで転職しました。当時遊んでいたケータイゲームのオープニングにJAVAのロゴを見つけて、これだ、プログラムだ、と。それから14年、エンジニアとして組織に属したり、フリーランスで働いたりの繰り返しで、今に至ります。結構フラフラしてましたが、エンジニアの技術というのは強いもんで、生活に困った時期は一度もありません。\n\nコラボテクノに入社したのは、その前に3年ほどフリーランスをして、一人でつくるのに飽きてきたから。もっとスケールの大きな仕事、みんなの役に立つものを、みんなでつくり上げたい、と。まぁ、妻もいることだし、そろそろ、定時に出社して退社する、社会人らしい生活に戻したいというのもありました。そんなことを考えていた時に、別件で知り合った社長に誘われて、まんまと入社していました（笑）。"}
        imageSrcText2={WorkLifeBalance2Text2}
        text3Head={"好奇心の赴くままに、\n「とりあえずやってみる」。"}
        text3Body={"こんな感じでいろいろな経験を積めたのは、折々に出会いに恵まれたからですが、自分の好奇心が旺盛なところもあると思います。好奇心の幅が少し広いというか、JAVAとPHPにはじまり、いろいろな言語もかじってみたり、新しいwebサービスが登場したと聞けば、すぐにユーザー登録したり、開発キットをダウンロードして触ってみたり。節操ないともいいますが（笑）、それらが今の自分の糧になっていることは間違いない。エンジニアを続けるコツは、好奇心のままに「とりあえずやってみる」の精神なのかもしれません。\nエンジニアは、やりたくないことはやらない、という生き方を選べる職業です。僕も実際、そうしてきました。ただ、若いうちは、やってみたくないこともしてみると、おや面白いぞということがあったりします。食わず嫌いはもったいない。エンジニアにはいろいろなタイプがあるし、働き方もそれぞれ。ちょっとでも興味があるのなら、「とりあえずやってみる」のがいいと思います。育休も取りやすいし（笑）。"}
        imageSrcText3={WorkLifeBalance2Text3} />
    </ThemeProvider>
  );
}