import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee1Top from '../../../image/interviews/new_employee1_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/interview2"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"N.K"}
        entryYear={"2023"}
        title={isMobileScreen ? "自分の想像したことを、\n自分で実現できるのが嬉しい。" : "自分の想像した\nことを、自分で実現\nできるのが嬉しい。"}
        imageSrcTop={NewEmployee1Top}
        text1={"\n――N.Kさんはどうしてエンジニアになろうと思ったのですか？　就職活動の末、コラボテクノに入社した理由も教えてください。"}
        text2={"エンジニアを志望したきっかけは、大学のプログラミングの授業です。もともと自分の想像を実現する仕事に憧れていたので、探していたのはこれだ！と興奮しました。それからはIT業界のエンジニアをめざして、ゲーム業界なども視野に入れつつ就職活動し、コラボテクノに入社しました。学生だったので知識や技術不足が不安でしたが、面接で聞いた「コラボテクノで働けば、20代のうちに一人前の技術力が身に付く」という社長の言葉に惹かれて、この会社のお世話になろうと思いました。"}
        text3={"\n――いち早く技術を身につけるための選択だったのですね。2023年に新卒で入社してしばらく経ちますが、エンジニアに対する印象は変わりましたか？"}
        text4={"入社する前は、エンジニアは一人で黙々と仕事する、ちょっと孤独な職業なのかなとイメージしていました。でも、実際はそんなことなく、とくにコラボテクノは縦も横もつながりが強くて、一人で放って置かれることはまったくありませんでした。リモートワーク中も、先輩や同僚にチャットで質問や相談をすると、ほとんどリアルタイムで答えやアドバイスが返ってくるので、孤独とは無縁です。意外と人の温もりを感じる職業でした。今はまだ自分の担当分の作業で精一杯ですが、いずれは先輩方のように、プロジェクトの全体を把握した上で後輩の面倒も見られるようになりたいです。"}
        text5={"\n――N.Kさんは就職活動のときに、どんなことを考えていましたか？　エンジニアをめざす方にアドバイスをください。"}
        text6={"自分の想像したことを、自分で試行錯誤し、自分で実現していく。エンジニアは、学生の頃に憧れていた通りで、就職して良かったと思います。就職活動中は正直いろいろ心配しましたが、「心配事の90%は、実際には起こらない」というミシガン大の研究結果を頼りに、やりきりました。この事実は、心配してても仕方ない、とにかく行動せよ！と解釈できると思うんですね。確かに就職は自分の人生を決定づけてしまうし、新卒という大きなメリットは一度しか使えないので、やっぱり心配事が尽きません。それでも、行動を起こさなければ、どんなチャンスも掴めないと思います。もしもエンジニア職が気になったのなら、あまり心配しすぎずに、さっそく動き出しましょう！"}
      />
    </ThemeProvider>
  );
}