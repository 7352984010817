import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee5Top from '../../../image/interviews/new_employee5_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/interview6"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"S.S"}
        entryYear={"2024"}
        title={isMobileScreen ? "決め手は、フレキシブルな\n働き方ができること。" : "決め手は、\nフレキシブルな働き方ができること。"}
        imageSrcTop={NewEmployee5Top}
        text1={"\n――S.SさんはITに無縁の学生生活を送っていたと聞きました。どうしてプログラマーになったのか、就職に向けて重視したことを教えてください。"}
        text2={"就職について最初は漠然と、金融や専門商社を志望していました。それらの会社を自分なりに調べていくうちに、通勤にかかる時間や9時〜5時で終わらない働き方に、少し疑問を持ってしまって…。自分は仕事も大切だけど、それ以外の時間も大切にしたいんだな、と気づきました。それから働き方重視で探し直したら、ITエンジニア職に出会ったという感じです。大学は文学部なので、これまでITやプログラミングは頭にありませんでしたが、リモート主体のフレキシブルなワークタイムが就職の決め手となりました。"}
        text3={"\n――なるほど、柔軟な働き方を求めていたのですね。でも、予備知識なしにいきなりITを仕事にするのは大変ではなかったですか？"}
        text4={"文系出身なので、ITの何もかもが初めてで、わからないことがたくさんありました。それでも、コラボテクノの先輩方はフランクな方ばかりで質問しやすく、困った時に周囲を頼れないという状況にはなりませんでした。調べてもわからない場合は、先輩方に積極的に質問することで解決でき、自分のスキルアップにつながりました。新人研修ではJava言語を学び、配属された現場ではPHPを使っています。どちらも一からの勉強で大変でしたが、自分のスキルアップがそのまま仕事の成果に結びつくので、学ぶことが楽しいです。それに、自分が関わったシステムが正常に稼働した瞬間の達成感は、これまでに体験したことのない、凄まじいものがありました。まだキャリアは始まったばかりですが、プログラマーになって本当に良かったと思います。"}
        text5={"\n――プラグラマーとして一年経ったところでお尋ねします。新卒で入社したコラボテクノで、望んでいた働き方は実現できていますか？"}
        text6={"まだまだ覚えることは多いのですが、働き方はかなり望み通りできています。仕事でバリバリ働くことと、プライベートな時間を大切にすることは、矛盾しないと思うんです。コラボテクノの先輩方のワークスタイルを見ていると、そう実感します。ママとして子育てしながらプロジェクトを進めるチームリーダーがいたり、大きなシステムをつくり上げつつ趣味の大会で入賞したエンジニアがいたり…。就職の際に重視したフレキシブルな働き方で、ワークとライフの両方を充実させる理想的なモデルが目の前にいて、憧れますし、なるべくコミュケーションを取って、そのコツを知ろうとしています。他の会社を知らないので断言できませんが、ここまで社員一人ひとりの働き方を考えてくれるのは、コラボテクノだけなのではと思います。"}
      />
    </ThemeProvider>
  );
}