import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MidEntryPm from "../image/entry/mid_entry_pm.webp";
import MidEntrySe from "../image/entry/mid_entry_se.webp";
import MidEntryInfra from "../image/entry/mid_entry_infra.webp";
import MidEntryShallowSe from "../image/entry/mid_entry_shallow_se.webp";

const images = [
  { src: MidEntryPm, title: "プロジェクトマネージャー", link: "recruit/mid-entry-pm" },
  { src: MidEntrySe, title: "システムエンジニア", link: "recruit/mid-entry-se" },
  { src: MidEntryInfra, title: "クラウドエンジニア", link: "recruit/mid-entry-infra" },
  { src: MidEntryShallowSe, title: "経験浅 システムエンジニア", link: "recruit/mid-entry-shallow-se" },
];

const MidEntry = (props) => {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <Container maxWidth="md">
      <Grid container spacing={4} justifyContent="center">
        {images.map((item, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Box 
              component="a" 
              href={item.link} 
              target="_self" // 同じタブで表示
              rel="noopener noreferrer" 
              sx={{
                marginTop:"5%",
                marginBottom:"5%",
                display: "block",
                textAlign: "center",
                textDecoration: "none",
                borderRadius: 2,
                overflow: "hidden",
                boxShadow: 3,
                "&:hover": { opacity: 0.8 }
              }}
            >
              <Box 
                component="img"
                src={item.src}
                alt={item.title}
                sx={{ width: "100%", height: 200, objectFit: "cover" }}
              />
              <Box sx={{ p: 2, backgroundColor: "#25395E", color: "white" }}>
                <Typography variant="h6">{item.title}</Typography>
                <Typography variant="h7">詳細はこちら →</Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
    </ThemeProvider>
  );
};

export default MidEntry;
