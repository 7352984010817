import React from "react";
import GoogleMapReact from 'google-map-react';

export default function GoogleMap() {
  const defaultProps = {
    center: {
      lat: 35.64253416251038, // 田町_緯度
      lng: 139.75030798492196 // 田町_経度
    },
    zoom: 17
  };
  var icon = `${process.env.PUBLIC_URL}/image/company/icon.webp`;

  const handleApiLoaded = ({ map, maps }) => {
    new maps.Marker({
      map,
      position: defaultProps.center,
      icon: icon,
    });
  };

  return (
    <div style={{ height: '450px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyDpOYleLrCVZYhesVn9U-Lcd5kJnHbuxPQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={handleApiLoaded}
      >
      </GoogleMapReact>
    </div>
  );
}
