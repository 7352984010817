import { Box, Typography, Grid, ThemeProvider } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import WorkImg from "../image/web-photo-03.webp";
import SesImg from "../image/business_image_01.webp";
import WebImg from "../image/business_image_02.webp";
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";
import Tag from "../components/Tag";
import ContentsBox from "../components/ContentsBox";
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { createTheme } from '@mui/material/styles';

export default function AboutWork(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
        <Box className="App">
            <Box sx={{ backgroundColor: colorTheme.palette.creamGray.main }}>
                {/* PC版 */}
                <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
                    <div style={{ position: "relative", width: "100%", padding: "0px 10px 45% 0px" }}>
                        <img
                            src={process.env.PUBLIC_URL + WorkImg}
                            width="70%"
                            height="100%"
                            alt="トップ画像"
                            style={{ position: "absolute", right: 0 }}
                        />
                        <div
                            style={{
                                display: "inline-block",
                                width: "35%",
                                minWidth: "300px",
                                height: "83%",
                                backgroundColor: "white",
                                position: "absolute",
                                left: 0,
                                top: 170,
                                borderRadius: "0px 20px 20px 0px",
                                whiteSpace: "pre-line",
                                margin: "-140px 0 0 0",
                            }}
                        >
                          <Box sx={{ marginTop: "20px" }}> 
                            <Tag tagStyle="left" tagText="仕事のこと" />
                          </Box>
                            <Box style={{ padding: "10px 5px 0px 50px", textAlign: "left" }}>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        padding: "2% 0 2% 0",
                                        fontWeight: "bold",
                                        fontSize: {
                                            xs: "15px",
                                            sm: "20px",
                                            md: "25px",
                                            lg: "2vw",
                                            xl: "2.3vw",
                                        },
                                        color: colorTheme.palette.navy.main,
                                    }}
                                >
                                    {"コラボテクノの\n4つの事業"}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                      padding: { xs:"5px 0px", md:"30px 0px"},
                                      fontSize: {
                                            xs: "12px",
                                            sm: "12px",
                                            md: "15px",
                                            lg: "1.4vw",
                                            xl: "1.6vw",
                                        },
                                        color: colorTheme.palette.navy.main,
                                        lineHeight: "200%",
                                    }}
                                >
                                    {"コラボテクノはエンジニアの\nスキルノウハウを生かした\n4つの事業サービスを展開しています。"}
                                </Typography>
                            </Box>
                        </div>
                    </div>
                </Box>
                {/* スマホ版 */}
                <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
                    <img
                        src={process.env.PUBLIC_URL + WorkImg}
                        style={{ width: "100%" }}
                        alt="社員のことTop画像"
                    />
                </Box>
            </Box>

            {/* コンテンツ */}
            <Box sx={{
                display: { xs: "block", sm: "none", md: "none" },
                marginTop: "10%",
                paddingBottom: "5%",
                color: colorTheme.palette.navy.main,
                backgroundColor: colorTheme.palette.creamGray.main,
            }}>
                <ContentsBoxWithTag
                    tagStyle={{ float: "left" }}
                    tagText="仕事のこと"
                    title="コラボテクノの4つの事業"
                    text="コラボテクノは、エンジニアのスキル、ノウハウを生かした、4つの事業サービスを展開しています。"
                />
            </Box>
      <Box sx={{ position: "absolute", padding: "5% 0 0 0", display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
        <Tag tagStyle={"right"} tagText={"#1 SES"}></Tag>
      </Box>
      <Box sx={{ padding: "5% 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <img src={process.env.PUBLIC_URL + SesImg} style={{ width: '80%', padding: '5% 0 0 0' }} alt='社員のことTop画像'></img>
          </Grid>
          <Grid item sm={6} container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Box sx={{ width: "100%", padding: { md: "0 0 0 0", lg: "5% 10% 0 45px", xl: "10% 15% 0 0" }, display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" }, boxSizing: "border-box" }}>
              <Tag tagStyle={"left"} tagText={"#1 SES"}></Tag>
            </Box>
            <Box sx={{ width: "100%"}}>
              <ContentsBox
                title={"人とテクノロジーの\n発想で課題解決支援!"}
                text={
                  "お客さま企業に人材とスキル・ノウハウを提供するシステムエンジニアリングサービス(SES)。開発ではオブジェクト指向の開発言語やPHPなどのプログラミング。インフラではLinuxの設計構築をはじめ、AWSの構築支援を行います。"
                }></ContentsBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ position: "absolute", display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
        <Tag tagStyle={"right"} tagText={"#2 Web System"}></Tag>
      </Box>
      <Box sx={{ padding: "3% 0 0 0" }}>
        <Grid container spacing={1}>
          <Grid item sm={6}>
            <img src={process.env.PUBLIC_URL + WebImg} style={{ width: '80%', padding: '5% 0 0 0' }} alt='社員のことTop画像'></img>
          </Grid>
          <Grid item sm={6} container
            direction="column"
            justifyContent="space-between"
            alignItems="center">
            <Box sx={{ width: "100%",display: { xs: "none", sm: "block", md: "block", lg: "block", xl: "block" }, boxSizing: "border-box" }}>
              <Tag tagStyle={"left"} tagText={"#2 Web System"}></Tag>
            </Box>
            <Box sx={{ width: "100%"}}>
              <ContentsBox
                title={"調査分析から\n設計・開発運用保守までフル対応!"}
                text={
                  "会員管理や調査分析から設計・開発、テスト、保守運用まで、課題にシームレスに対応。ECサイト、コンテンツポータルサイト、広告配信システムの開発など、幅広い分野を担います。"
                }></ContentsBox>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid container Spacing={1} >
        <Grid item sm={6}>
          <Box sx={{ px: { md: 5 }, padding: "2% 0 0 0" }}>
            <ContentsBoxWithTag
              tagStyle={{ float: "left" }}
              tagText={"#3  LIVE Casting"}
              title2={"クリエイティブチームが\nライブ配信をサポート"}
              text2={
                "イベントや講演、教育、後方、採用などの幅広いシーンで、ライブ配信やオンデマンド配信を実現。クリエイティブチームが撮影から現場対応、インターネット配信までトータル的にサポートします。"
              }
            ></ContentsBoxWithTag>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ px: { md: 5 }, padding: "2% 0 0 0" }}>
            <ContentsBoxWithTag
              tagStyle={"left"}
              tagText={"#4 Pro Assign"}
              title2={"フリーランスに特化した\n案件マッチングサービス"}
              text2={
                "フリーランスエンジニアに特化した案件マッチングサービス「コラビィ」を運営。専任のコーディネーターがマッチした案件を提供し、契約交渉から案件管理、報酬管理まで全面的にサポートします。\n"
              }
            ></ContentsBoxWithTag>
          </Box>
        </Grid>
      </Grid>
      <ScrollToTopIcon />
    </Box>
    </ThemeProvider>
  );
}
