import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee2Top from '../../../image/interviews/new_employee2_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/interview3"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"M.S"}
        entryYear={"2022"}
        title={isMobileScreen ? "新卒から濃密な3年で、自分の\nスキルと考え方の成長を実感。" : "新卒から濃密な\n3年で、自分の\nスキルと考え方の\n成長を実感。"}
        imageSrcTop={NewEmployee2Top}
        text1={"\n――M.Sさんは就職活動で、どんなことを重視しましたか？　エンジニア志望の動機とコラボテクノに入社した理由も教えてください。"}
        text2={"学生の頃はゲームが好きで、少しプログラミングをかじったこともあり、その流れでITエンジニアになろうと思いました。とはいえ、スキルレベルは未経験同然でしたので、しっかり技術力が付くところを探して就職活動していました。当時コラボテクノは初めて新卒を採用するということで、リクルートにかなり力が入っていて、新人研修やスキル養成プランが他と比べても実践的で質の高い内容だったように思います。さらに、これまでも異業種からの転職を多く受け入れていて、その育成ノウハウの蓄積もあるという話を聞いて、納得して入社を決めました。"}
        text3={"\n――やはり知識やスキル不足は、新卒・未経験だと気になりますよね。入社から数年経ちますが、エンジニアとして成長したなと思う部分はどんなところですか？"}
        text4={"新卒・未経験からのスタートで数年、日々学びながら成長でき、それなりのスキルも身に付いたと思います。たとえば直近でシステムの「性能試験」を担当したのですが、過去に例のない異常事象をあれこれ試行錯誤しながら自分で解決できました。これは技術力はもちろん、考え方の面で「事実を並べていくこと」の大切さに気づけて、自分の成長を実感しました。異常の原因を手当たり次第に探すのではなく、起きた事象を段階的に、事実のみを並べて整理し考察する。あらゆる異常事象に対して通用するこの考え方を得て、大きな自信につながっています。思うにエンジニアは、他の職業と比べて、自己成長を実感しやすい職業といえるのではないでしょうか。"}
        text5={"\n――これからエンジニアをめざす方に、エンジニアの働き方やコラボテクノについて、実体験に基づくメッセージをください。"}
        text6={"エンジニア職には当時ブラックな労働環境のイメージが蔓延していて、私も心配していたのですが、実態はまったく異なりました。この3年、基本的にリモートで勤務し、定時に始まって、定時に終わっています。残業は数えるほどで、毎日、自由時間がしっかり取れて、友人とも遊べますし、自分でも驚くほどホワイトな環境でした。また、会社は風通しが良く、社長や先輩方ともいい距離感を保てて、新人の意見も聞いてくれますし、わからないことは優しく教えてくれます。これからエンジニアへの就職、コラボテクノへの入社を考えている方には、安心してくださいと伝えたいですね。"}
      />
    </ThemeProvider>
  );
}