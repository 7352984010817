import * as React from "react";
import DropdownSection from "../components/DropdownSection";
import DropdownItem from "../components/DropdownItem";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle) {
      document.title = `${props.pageTitle}｜コラボテクノ株式会社`;
    }
  }, [props.pageTitle]); // ← 空の依存配列を追加

  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          fontFamily: "Arial, sans-serif",
          padding: "32px",
          maxWidth: "900px",
          margin: "0 auto",
          borderRadius: "16px",
        }}
      >
        <DropdownSection 
          title={"クラウドエンジニア"}
          buttonText="応募する"
          buttonUrl="https://forms.gle/nWpW7iaq6XpuzPfR9"
        >
        <div style={{ fontSize: "18px", color: "#333", marginBottom: "24px" }}>
          求人の詳細は下記の各項目をクリックしてご確認ください。
        </div>
        <DropdownItem
          number="01"
          title="募集背景"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>当社「コラボテクノ」では、現在、大手のSierに対し、</li>
                <li>WEB開発、クラウド基盤構築を中心にしたSES事業を中心にいますが、</li>
                <li>今後これに加え、企業のIT化支援（DX推進やITコンサルティング）を展開したいと考えおり</li>
                <li>今後の事業の仲間になっていただける方を積極的に募集します。</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="02"
          title="業務概要"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>クラウド基盤を活用したシステム設計・構築を担当するクラウドエンジニアを募集しています。</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="03"
          title="開発プロジェクトの実績"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■大手不動産会社のクラウドリフト</h3>
                <li>100以上のオンプレサーバーで稼働しているサービスをAzureへ移行する案件</li>
                <li>情報システム部門の一員として現場リーダーとプロジェクトを推進する</li>
                <br/>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■証券会社向け　OCI(Oracle Cloud)基盤構築</h3>
                <li>TerraformやAnsibleといったIaCツールを利用し、OCI(Oracle Cloud)上に基盤を構築</li>
                <li>構築対象にはOKE(OCIマネージドのkubernetes)があり、マニフェストファイルの開発</li>
                <li>も行う</li>
                <br/>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>■通信キャリア向け　AWSの設計、開発</h3>
                <li>Terraform、Ansibleを使用して基盤のAWSの基盤の構成管理を行いkubernetes</li>
                <li>istioを使って複数のアプリケーションを疎結合にしたマイクロサービス基盤の構築を行う</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="04"
          title="応募資格・条件"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>【必須スキル】</li>
                <li>■Webアプリケーション（3年以上且つ何らかのサーバ側設定）またはインフラ開発経験のある方    </li>
                <li>■何らかのクラウドの構築に携わったことがある</li>
                <li>■チームワークを大切にし、成長に対する強いモチベーションがある方</li>
                <li>■社内イベントにも積極的に参加出来る方</li><br/>
                <li>【歓迎スキル】</li>
                <li>■Linuxサーバ　設計～構築の経験</li>
                <li>■IaCの構築の経験</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="05"
          title="募集人数"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>2-3名</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="06"
          title="勤務形態"
          subtitle=""
          content={
            <div>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務地】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>東京都港区芝浦3丁目17-11天翔田町ビル1002号</li>
                <li>(自社内で開発を行う場合と、顧客先で開発を行う場合があります)</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務時間】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>完全土日祝休み 9:30 ～ 18:30</li>
                <li>（顧客先により異なる場合があります。）</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【休日休暇】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 年間休日120日以上</li>
                <li>◇ 夏季休暇</li>
                <li>◇ 年末年始休暇</li>
                <li>◇ 産前産後休暇・育児休暇制度</li>
              </ul><br/>
            </div>
          }
        />
        <DropdownItem
          number="07"
          title="福利厚生・待遇"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 雇用保険</li>
                <li>◇ 厚生年金</li>
                <li>◇ 労災保険</li>
                <li>◇ 健康保険</li>
                <li>◇ 交通費支給あり</li>
                <li>◇ テレワーク・在宅OK</li>
                <li>◇ 時短勤務制度あり</li>
                <li>◇ 資格取得支援（受験料・書籍購入・メンター相談）</li>
                <li>◇ チーム懇親会費用負担</li>
                <li>◇ 技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能</li>
                <li>◇ フリーワーク制度（社内副業）</li>
                <li>◇ アルムナイ採用・リファラル採用積極的に受け入れ中</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="08"
          title="給与"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>年俸　5,000,000円　～　8,000,000円</li>
                <li>入社当初のスキルによって変動あり</li>
                <li>みなし残業時間30時間(78,459円〜125,610円)</li>
                <li>【給与詳細】</li>
                <li>・30時間超過分は別途支給</li>
                <li>・別途業績に応じた賞与を支給（年1回）</li>
                <li>・ミッショングレード制をテスト施行中</li>
                <li>（年功序列ではなく、スキルレベルに応じた給料体系となっております。）</li>
                <li>・昇給は年１回です。</li>
                <li>（人事考課制度1回、四半期ごとの振り返りでの目標管理をしております。）</li>
              </ul>
            </div>
          }
        />
      </DropdownSection>
    </div>
    </ThemeProvider>
  );
};
