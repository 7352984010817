import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee6Top from '../../../image/interviews/new_employee6_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/work-life-interview1"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"R.H"}
        entryYear={"2024"}
        title={isMobileScreen ? "働き方の自由を求めたら、\nエンジニアでした。" : "働き方の自由を\n求めたら、\nエンジニアでした。"}
        imageSrcTop={NewEmployee6Top}
        text1={"\n――教育学部の出身と聞きましたが、どうしてエンジニアになったのですか？　またコラボテクノに入社した経緯も教えてください。"}
        text2={"　大学は教育学部で、勉強や研究は楽しかったのですが、教員になるつもりはまったくありませんでした。柔軟な働き方に関心があり、就職はIT業界に絞って活動しました。とはいえ、そもそもIT業界のことをよく知りませんし、就活の進め方もわからない状態で、暗中模索の日々でした。そんななか、いろいろ参加した合同会社説明会で出会ったのが、コラボテクノです。第一印象で興味が湧き、会社独自の就活イベントにも参加してみたところ、会社の雰囲気や社員の皆さんの人柄の良さ、そして代表との距離の近さに魅力を感じ、入社を決めました。"}
        text3={"\n――以前と比べて、就職時に働き方を重視する人が増えている印象です。実際のところ入社してみて、コラボテクノはどんな会社でしたか？"}
        text4={"　新人のOJTの現場はフルリモートで、自宅でも仕事できますが、私は週2回コラボテクノに出社して、デスクで仕事をするようにしています。社会人としてのマナーやコミュニケーションスキルも大切ですし、目の前にお手本のいる環境でそつなく身につけたいと思ったからです。また今後のために、代表や営業の社員さんなどの仕事ぶりも見て、経営やビジネスを理解しておきたいとも考えました。コラボテクノで働くと、会社にも現場にも活躍している女性が多く、働く女性のロールモデルが何人もいるのは恵まれているなと感じます。私もそうなりたいし、いずれ後輩にもそう思われる女性でありたいなと思い、日々頑張っています。"}
        text5={"\n――女性のロールモデルの話がありましたが、自分の今後についてはどう考えていますか？　またエンジニア職のいいところを教えてください。"}
        text6={"　私は縁あってエンジニア職に就きましたが、生涯エンジニアと決めつけているわけではありません。当然その道もあるでしょうし、この先チームリーダーやプロジェクトマネージャーといった管理サイドの業務をすることで、もしかしたら営業や企業経営の方向に興味が湧くかもしれません。一方で、これからプライベートやライフステージの変化で、働くことに対する心境や価値観が変わる可能性だって充分にあります。社会に出たからといって、今から自分の生き方を決めつけてしまうのは、もったいないです。私が求めた柔軟な働き方とは、場所や業務時間だけでなく、生き方を自分で選べる自由があることなのだと思います。同じように自由にいろいろ楽しみたい人は、エンジニアから始めるのがおすすめです。"}
      />
    </ThemeProvider>
  );
}