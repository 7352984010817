import { Box } from '@mui/material';
import * as React from 'react';
import { colorTheme } from '../const';
import ContentsBox from '../components/ContentsBox';
import { useMediaQuery } from 'react-responsive';
import Footer from './corporate/footer';
import ScrollToTopIcon from './../components/ScrollToTopIcon';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Sitemap(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })

  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{
      backgroundColor: `${colorTheme.palette.creamGray.main}`,
    }}>
      <Box sx={{ margin: '0px 0px -30px -20px' }}><ContentsBox title={"サイトマップ"} titleStyle={{ fontSize: isMobileScreen ? '3vw' : '2.5vw' }} /></Box>
      <hr style={{ border: "none", borderTop: "1px dotted" }}></hr>
      <div maxWidth="sm" style={{ fontSize: isMobileScreen ? '3vw' : '2vw', lineHeight: '150%' }}>
        <ul >
          <div style={{ display: isMobileScreen ? '' : 'flex', justifyContent: 'space-around', padding: '10px 10px 50px 0px' }}>
            <div style={{
              textAlign: 'left',
              justifyContent: 'space-between',
              flexDirection: 'column',
              whiteSpace: 'pre-line',
              display: 'flex',
              width: isMobileScreen ? '100%' : '50%',
              padding: isMobileScreen ? '0px' : '15px 40px'
            }}>
              <div>
                <li><a href="/">・TOP</a></li>
                <li><a href="/service">・サービス</a></li>
                <li><a href="/about" > ・会社情報</a></li>
                <li><a href="/contact" > ・お問い合わせ</a></li>
                <li><a href="/privacy" > ・プライバシーポリシー</a></li>
                <li><a href="/security" > ・個人情報の取り扱いについて</a></li>
                <li><a href="/sitemap" > ・サイトマップ</a></li>
              </div>
            </div>
            <div style={{
              textAlign: 'left',
              justifyContent: 'space-between',
              flexDirection: 'column',
              whiteSpace: 'pre-line',
              display: 'flex',
              width: isMobileScreen ? '100%' : '80%',
              padding: isMobileScreen ? '0px ' : '15px 40px'
            }}>
              <div>
                <details open >
                  <summary><a href="/recruit" > 採用情報TOP</a ></summary>
                  <li><a href="/recruit/company-info" > 　・会社のこと</a></li>
                  <details>
                    <summary><a href="/recruit/member" >・社員のこと</a ></summary>
                    <li><a href="/recruit/member/interview1" > 　　・新卒入社社員インタビュー</a></li>
                    <li><a href="/recruit/member/interview2" > 　　・新卒入社社員インタビュー</a></li>
                    <li><a href="/recruit/member/interview3" > 　　・新卒入社社員インタビュー</a></li>
                    <li><a href="/recruit/member/job-change-interview1" > 　　・異業種転職インタビュー</a></li>
                    <li><a href="/recruit/member/job-change-interview2" > 　　・異業種転職インタビュー</a></li>
                    <li><a href="/recruit/member/work-life-interview1" > 　　・ワークライフバランスインタビュー</a></li>
                    <li><a href="/recruit/member/work-life-interview2" > 　　・ワークライフバランスインタビュー</a></li>
                    <li><a href="/recruit/member/discussion" > 　　・対談</a></li>
                  </details>
                  <li><a href="/recruit/work" > 　・仕事のこと</a></li>
                  <li><a href="/recruit/benefits" > 　・福利厚生のこと</a></li>
                  <details>
                    <summary><a href="/recruit/new-graduates" > ・新卒採用</a></summary>
                    <a href="/recruit/entry" > 　・募集エントリー</a>
                  </details>
                  <details>
                    <summary><a href="/recruit/mid-career" > ・中途採用</a></summary>
                    <a href="/recruit/mid-career/Mid-entry" > 　・募集エントリー</a>
                  </details>
                </details>
              </div>
            </div>
          </div>
        </ul >
      </div>
      <ScrollToTopIcon />
      <Footer />
    </Box>
    </ThemeProvider>
  )
}