import * as React from 'react';
import Footer from './footer';
import LinkIcon from '@mui/icons-material/Link';
import { Box, Grid, Typography } from '@mui/material';
import SquareIcon from '@mui/icons-material/Square';
import ServiceImage from '../../components/corporate/ServiceImage';
import imgElearning from '../../image/company/service/E-learning_0217.webp';
import imgRecruit1 from '../../image/company/service/求人検索.webp';
import imgFinancial from '../../image/company/service/AdobeStock_金融_0217.webp';
import imgRecruit2 from '../../image/company/service/AdobeStock_求人検索_0217.webp';
import imgInsurance from '../../image/company/service/AdobeStock_保険_0217.webp';
import imgCarrier from '../../image/company/service/携帯キャリア系_0217.webp';
import imgEC from '../../image/company/service/ECサイト系_0217.webp';
import imgDesign from '../../image/company/service/HP_design_0217.webp';
import imgInfra from '../../image/company/service/HP_インフラ_0217.webp';
import imgDatabase from '../../image/company/service/DB設計_0217.webp';
import imgDevelop from '../../image/company/service/HP_システム開発_0217+.webp';
import imgMaintenance from '../../image/company/service/HP保守運用_0217+.webp';
import imgPWA from '../../image/company/service/HP_pwa_0217.webp';
import imgSP from '../../image/company/service/sp_0217.webp';
import imgIpad from '../../image/company/service/HP_ipad_0217.webp';
import imgAWS from '../../image/company/service/HPAWs_0217+.webp';
import imgPrjEx from '../../image/company/service/CT_HP_project事例.webp';
import ScrollToTopIcon from '../../components/ScrollToTopIcon';

export default function Service(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  return (
      <body>
        <div class="wrapper clearfix">
          <article class="onethird">
            <div class="spwrapper">

              <div id="sec1" class="companybox clearfix">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Box className="companybox-service">
                      <h3 class="underlineSP">WEBシステム開発</h3>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className="service-description">
                      <Typography fontSize={"14px"} style={{ color: '#000', paddingBottom: '1.5rem', lineHeight: '160%' }}>
                        {"調査分析から設計・開発、テスト、保守運用まで、課題にシームレスに対応。\nWEBシステム開発では、ECサイト、コンテンツポータルサイト、会員管理、広告配信システムの開発など、\nお客様のさまざまなご要望に対して幅広くお応えしていくことが可能です。\nまた、顧客情報管理、営業支援システムなどCRMやCMSのパッケージの導入における新規の開発ならびに保守開発においても、\n設計から開発、運用までトータルにサポートいたします。"}
                      </Typography>
                      <div style={{ display: 'flex' }}>
                        <Typography fontSize={"14px"} className='service-detail-list'>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />開発言語
                        </Typography>
                        <Typography fontSize={"14px"}>Java / JavaScript / TypaScript（React） / PHP</Typography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Typography fontSize={"14px"} className='service-detail-list'><SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />データベース</Typography>
                        <Typography fontSize={"14px"}>MySQL / PostgreSQL / Oracle / SQLserver</Typography>
                      </div>
                      <div style={{ display: 'flex' }}>
                        <Typography fontSize={"14px"} className='service-detail-list'><SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />インフラ</Typography>
                        <Typography fontSize={"14px"}>AWS / GCP / Microsoft Azure</Typography>
                      </div>
                      <div style={{ display: 'flex', paddingTop: '2%' }}>
                        <ServiceImage imageSrc={imgElearning} alt="Eラーニング" text={'イーラーニング\nシステム'} />
                        <ServiceImage imageSrc={imgRecruit1} alt="フリーランス向け求人サイト検索サービス" text={'フリーランス向け\n求人サイト\n検索サービス'} />
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>

              <div id="sec2" class="companybox clearfix">
              <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Box className="companybox-service">
                      <h3 class="underlineSP">システムエンジニアリング<br />サービス(SES)</h3>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className="service-description">
                      <h4>人と、テクノロジーで、お客様の課題解決を支援する</h4>
                      <Typography fontSize={"14px"} style={{ color: '#000', paddingBottom: '1.5rem', lineHeight: '160%' }}>
                        {"主に通信系、物流系、金融系のシステムが中心です。その他、サービス系Webサイトの開発、スマートフォンなどのモバイル系の開発にも対応できます。\nまた、PM、PMOの立ち位置で、プロジェクトの上流から進捗管理、納品まで責任をもってプロジェクトをマネジメントします。\nAWSなどのクラウドを利用した開発基盤やサーバレス環境の構築にも力を入れています。"}
                      </Typography>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />プロジェクトの役割
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>PM、PMO、SE、PG</Typography>
                      </div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />開発手法
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>アジャイル（Scrum）開発、ウォーターフォール開発</Typography>
                      </div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />開発言語
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>Java /JavaScript（React,Vue,Angular）/Python/PHP/ C#</Typography>
                      </div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />フレームワーク
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>SpringBoot、Spring、Django、Flask、Laravel、.Net</Typography>
                      </div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />クラウド
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>AWS（EC2、EKS、Lambda、RDS For Oracle、MongoDB） / GCP / Microsoft Azure</Typography>
                      </div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />データベース
                        </Typography>
                        <Typography fontSize={"14px"} style={{ paddingBottom: '0.5em' }}>MySQL / PostgreSQL / Oracle / SQLserver</Typography>
                      </div>
                      {/* スマホ */}
                      <Grid container sx={{ display: { xs: 'flex', sm: 'none' }}}>
                        <div style={{ display: 'flex', paddingTop: '2%' }}>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgFinancial} alt="金融系" text={'金融系'} />
                            <ServiceImage imageSrc={imgRecruit2} alt="企業求人系" text={'企業求人系'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgInsurance} alt="保険系" text={'保険系'} />
                            <ServiceImage imageSrc={imgCarrier} alt="通信キャリア系" text={'通信キャリア系'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgEC} alt="ECサイト系" text={'ECサイト系'} />
                          </Grid>
                        </div>
                      </Grid>
                      {/* PC */}
                      <div style={{ display: 'flex', paddingTop: '2%' }}>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }}}>
                          <ServiceImage imageSrc={imgDesign} alt="UI/UXデザイン" text={'UI/UXデザイン\n(PC・スマホ対応)'} />
                          <ServiceImage imageSrc={imgInfra} alt="インフラ設計" text={'インフラ設計'} />
                          <ServiceImage imageSrc={imgDatabase} alt="データベース設計" text={'データベース\n設計'} />
                          <ServiceImage imageSrc={imgDevelop} alt="システム開発" text={'システム開発'} />
                          <ServiceImage imageSrc={imgMaintenance} alt="保守・運用" text={'保守・運用'} />
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>

              <div id="sec3" class="companybox clearfix">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Box className="companybox-service">
                      <h3 class="underlineSP">開発領域</h3>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className="service-description">
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />WEBシステム開発
                        </Typography>
                        <Typography fontSize={"14px"}>当社では、PCやスマホやタブレットなどモバイルでのWEBサイト構築やそれと連動する顧客管理システムの開発を多く手掛けています。得意とする領域ですので、開発のチームでのリーダー業務やテックリードとしての役割を担うことが多い分野です。</Typography>
                      </div>
                      {/* スマホ */}
                      <Grid container sx={{ display: { xs: 'flex', sm: 'none' }}}>
                        <div style={{ display: 'flex', paddingTop: '2%' }}>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgDesign} alt="UI/UXデザイン" text={'UI/UXデザイン\n(PC・スマホ対応)'} />
                            <ServiceImage imageSrc={imgInfra} alt="インフラ設計" text={'インフラ設計'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgDatabase} alt="データベース設計" text={'データベース\n設計'} />
                            <ServiceImage imageSrc={imgDevelop} alt="システム開発" text={'システム開発'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgMaintenance} alt="保守・運用" text={'保守・運用'} />
                          </Grid>
                        </div>
                      </Grid>
                      {/* PC */}
                      <div style={{ display: 'flex', paddingTop: '2%' }}>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }}}>
                          <ServiceImage imageSrc={imgDesign} alt="UI/UXデザイン" text={'UI/UXデザイン\n(PC・スマホ対応)'} />
                          <ServiceImage imageSrc={imgInfra} alt="インフラ設計" text={'インフラ設計'} />
                          <ServiceImage imageSrc={imgDatabase} alt="データベース設計" text={'データベース\n設計'} />
                          <ServiceImage imageSrc={imgDevelop} alt="システム開発" text={'システム開発'} />
                          <ServiceImage imageSrc={imgMaintenance} alt="保守・運用" text={'保守・運用'} />
                        </Box>
                      </div>
                      <div style={{ padding: '0.9em' }}></div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />アプリケーション開発
                        </Typography>
                        <Typography fontSize={"14px"}>パソコンやスマートフォンのブラウザ上で利用できるアプリケーション、Webアプリの開発が中心。企業の通販サイトやWebサービスなどがこれに当たります。最近では、Webアプリの技術を使用しながら、カメラやGPS機能のアプリと連動させたアプリケーション開発が多くなっています。フロントは、Javascript（typescript,vue,react）で、サーバレス環境（AWS,Lambda）で開発することも少なくありません。</Typography>
                      </div>
                      <div style={{ display: 'flex', paddingTop: '2%' }}>
                        <Box sx={{ display: { xs: 'inherit', sm: 'inherit' }}}>
                          <ServiceImage imageSrc={imgPWA} alt="PWA" text={'PWA'} />
                          <ServiceImage imageSrc={imgSP} alt="iOS/Android" text={'iOS/Android'} />
                          <ServiceImage imageSrc={imgIpad} alt="iPad" text={'iPad'} />
                        </Box>
                      </div>
                      <div style={{ padding: '0.9em' }}></div>
                      <div className="service-desc-text">
                        <Typography fontSize={"14px"} className='service-detail-list' style={{ minWidth: '180px' }}>
                          <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />基幹システム開発
                        </Typography>
                        <Typography fontSize={"14px"}>基幹システムは、業務の核となる情報を管理するシステム（またはソフトウェア）の総称です。より分かりやすく言えば、基幹システムは「業務を行うのに絶対必要となるシステム」という認識。業務の重要な情報管理を担うバックオフィスに集中しているため、「バックオフィス系」と呼ばれることもあります。ここではまさにシステムエンジニアとしての経験やスキルが重要で、当社のSEのみならず、パートナー企業のシステムエンジニアと連携して開発をすることも多くなります。</Typography>
                      </div>
                      {/* スマホ */}
                      <Grid container sx={{ display: { xs: 'flex', sm: 'none' }}}>
                        <div style={{ display: 'flex', paddingTop: '2%' }}>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgInfra} alt="インフラ設計" text={'インフラ設計'} />
                            <ServiceImage imageSrc={imgDatabase} alt="データベース設計" text={'データベース\n設計'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgDevelop} alt="システム開発" text={'システム開発'} />
                            <ServiceImage imageSrc={imgMaintenance} alt="保守・運用" text={'保守・運用'} />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <ServiceImage imageSrc={imgAWS} alt="AWS" text={'AWS'} />
                          </Grid>
                        </div>
                      </Grid>
                      {/* PC */}
                      <div style={{ display: 'flex', paddingTop: '2%' }}>
                        <Box sx={{ display: { xs: 'none', sm: 'flex' }}}>
                          <ServiceImage imageSrc={imgInfra} alt="インフラ設計" text={'インフラ設計'} />
                          <ServiceImage imageSrc={imgDatabase} alt="データベース設計" text={'データベース\n設計'} />
                          <ServiceImage imageSrc={imgDevelop} alt="システム開発" text={'システム開発'} />
                          <ServiceImage imageSrc={imgMaintenance} alt="保守・運用" text={'保守・運用'} />
                          <ServiceImage imageSrc={imgAWS} alt="AWS" text={'AWS'} />
                        </Box>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
              </div>

              <div id="sec4" class="companybox pdB60">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Box className="companybox-service">
                      <h3 class="underlineSP">プロジェクト事例</h3>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className="service-description">
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />通信キャリア向け マイポータルWebアプリサービス
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />物流会社向け 荷物追跡サービス　タブレット対応
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />保険（見積・契約）Webアプリサービス
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />ネットバンキング顧客管理Webシステム
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />損害保険会社向けリスク分析システム
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />医療系診察予約Webサービス
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />アパレル会社向けECサイト管理システム
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />不動産会社向け 基幹システムのWeb化プロジェクト
                      </Typography>
                      <Typography fontSize={"14px"} className='service-detail-list' style={{ paddingBottom: '0.5em' }}>
                        <SquareIcon sx={{ fontSize: 15 }} style={{verticalAlign: "text-top"}} />通信キャリア向け 顧客対応システム（下記例）
                      </Typography>
                      <Typography fontSize={"14px"} style={{ color: '#000', paddingBottom: '1.5rem', lineHeight: '160%' }}>
                        ▶ 元請直で、当社より社員5名(内1名は開発リーダ)、協力会社10名参画<br />
                        ▶ 携帯SHOPで、契約内容やプラン変更などを受け付けるシステム<br />
                        ▶ Java、SpringBootベースで、機能によりKotlinやvue.js、reactなどを使用
                      </Typography>
                      <Box style={{ textAlign: 'center' }}>
                        <img src={process.env.PUBLIC_URL+imgPrjEx} alt="プロジェクト事例" width="90%" />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </div>

              <div id="sec5" class="companybox clearfix">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={5}>
                    <Box className="companybox-service">
                      <h3 class="underlineSP">フリーランスエンジニアの案件情報<br />「コラビィ」</h3>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <Box className="service-description">
                      <Typography fontSize={"14px"} style={{ color: '#000', paddingBottom: '1.5rem', lineHeight: '160%' }}>
                        {"フリーランスエンジニアに特化した案件マッチングサービスです。\n専任のコーディネーターがあなたにマッチした案件を提供し、契約交渉から案件管理、報酬管理まで全面的にサポートします。"}
                      </Typography>
                      <p class="bold_link">
                        <a href="https://collabe.pro/" target="_blank" rel="noreferrer"><LinkIcon fontSize="small" style={{verticalAlign: "text-bottom"}} />フリーランスエンジニアの案件情報 「コラビィ」</a>
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </div>

          </div>
        </article>
      </div>
      <ScrollToTopIcon />
      <Footer />
    </body>
  )
}
