import * as React from "react";
import DropdownSection from "../components/DropdownSection";
import DropdownItem from "../components/DropdownItem";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "32px",
        maxWidth: "900px",
        margin: "0 auto",
        borderRadius: "16px",
      }}
    >
      <DropdownSection 
        title="営業"
        buttonText="応募する"
        buttonUrl="https://forms.gle/nWpW7iaq6XpuzPfR9"
      >
        <div style={{ fontSize: "18px", color: "#333", marginBottom: "24px" }}>
          以下は、具体的な案件事例の一覧です。各項目をクリックして詳細を確認してください。
        </div>
        <DropdownItem
          number="01"
          title="仕事内容"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>当社「コラボテクノ」では、今まで大手Sierに対しSES事業を中心に行ってきましたが、</li>
                <li>今後、直接、企業のIT支援（DX推進やITコンサルティング）の強化を考えています。</li>
                <li>今回は、この事業領域の拡大に伴い、</li>
                <li>営業経験を活かして当社の成長をサポートしてくださる営業担当者を募集します。</li>
                <li>具体的には以下の業務を担当いただきます。</li>
                <li>・新規顧客開拓および既存顧客との関係強化</li>
                <li>・受託開発およびSESビジネスの提案営業</li>
                <li>・DXやITコンサルティングに関連するプロジェクトの提案、契約交渉、進捗管理</li>
                <li>・エンジニアチームとの連携、提案内容に基づいた技術面の調整</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="02"
          title="応募資格・条件"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>【求めるスキル・経験】</li>
                <li>■IT業界での法人営業経験/無形商材の法人営業経験 5年以上</li>
                <li>■新規開拓や提案型営業の実績</li><br/>
                <li>【歓迎スキル】</li>
                <li>受託開発やSES営業の経験がある方歓迎</li>
                <li>SIerやSES事業に関連する顧客基盤をお持ちの方</li>
                <li>チームリーダーシップ、プロジェクトマネジメント経験</li>
                <li>当社のレクリエーションイベント等にも積極的に参加いただける方</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="03"
          title="募集人数"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>1名</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="04"
          title="勤務形態"
          subtitle=""
          content={
            <div>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務地】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>東京都港区新橋3-11-8 オーイズミ新橋第2ビル9階</li>
                <li>(自社内で開発を行う場合と、顧客先で開発を行う場合があります)</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務時間】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>完全土日休み 9:30 ～ 18:30</li>
                <li>休憩時間:12:00〜13:00（60分）</li>
                <li>平均残業時間:平均10時間/月 ※基本残業は多く発生しません。</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【休日休暇】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 年間休日120日以上</li>
                <li>◇ 夏季休暇</li>
                <li>◇ 年末年始休暇</li>
                <li>◇ 産前産後休暇・育児休暇制度</li>
              </ul><br/>
            </div>
          }
        />
        <DropdownItem
          number="05"
          title="福利厚生"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 雇用保険</li>
                <li>◇ 厚生年金</li>
                <li>◇ 労災保険</li>
                <li>◇ 健康保険</li>
                <li>◇ 交通費支給あり</li>
                <li>◇ テレワーク・在宅OK</li>
                <li>◇ 時短勤務制度あり</li>
                <li>◇ 資格取得支援（受験料・書籍購入・メンター相談）</li>
                <li>◇ チーム懇親会費用負担</li>
                <li>◇ 技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能</li>
                <li>◇ フリーワーク制度（社内副業）</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="06"
          title="給与"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>年収 500万～800万円（経験・スキルに応じて決定）</li>
                <li>月給は、12分割で支給、見なし固定残業45時間分含む</li><br/>
                <li>【給与詳細】</li>
                <li>・45時間超過分は別途支給</li>
                <li>・別途業績に応じた賞与を支給（年1回）</li>
                <li>・ミッショングレード制をテスト施行中</li>
                <li>（年功序列ではなく、スキルレベルに応じた給料体系となっております。）</li>
                <li>・昇給は年１回です。</li>
                <li>（人事考課制度1回、四半期ごとの振り返りでの目標管理をしております。）</li>
              </ul>
            </div>
          }
        />
      </DropdownSection>
    </div>
    </ThemeProvider>
  );
};
