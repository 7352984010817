import * as React from "react";
import DropdownSection from "../components/DropdownSection";
import DropdownItem from "../components/DropdownItem";
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function Entry(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        padding: "32px",
        maxWidth: "900px",
        margin: "0 auto",
        borderRadius: "16px",
      }}
    >
      <DropdownSection 
        title="新卒採用（エンジニア）"
        buttonText="応募する"
        buttonUrl="https://forms.gle/XeWKThHMq2r43BBj6"
      >
        <div style={{ fontSize: "18px", color: "#333", marginBottom: "24px" }}>
          26卒向けの選考を開始しました。（2024年10月～2025年5月には終了予定。）<br/>
          各項目をクリックして詳細を確認してください。
        </div>
        <DropdownItem
          number="01"
          title="選考フロー"
          subtitle="2025年1月まで"
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}> 
                <li>プログラミング体験イベント参加</li>
                <li>↓</li>
                <li>エントリー</li>
                <li>↓</li>
                <li>オンライン人事面接（カジュ面）</li>
                <li>↓</li>
                <li>オンライン座談会（コラボエンジニアとの接点）</li>
                <li>SPI受験</li>
                <li>↓</li>
                <li>最終面接</li><br/>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="02"
          title="選考フロー"
          subtitle="2025年2月～"
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}> 
                <li>代表とのカジュアル面談</li>
                <li>↓</li>
                <li>人事との一次面接</li>
                <li>↓</li>
                <li>オンライン座談会（コラボエンジニアとの接点）</li>
                <li>SPI受験</li>
                <li>場合によって、論理的思考力を確認するテスト＆面談</li>
                <li>↓</li>
                <li>代表との最終面接</li><br/>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="03"
          title="入社後一年間の流れ"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>4月～6月</h3>
                <li>・Java研修</li>
                <li>・新歓BBQ</li>
                <br/>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>7月～</h3>
                <li>・先輩と共に現場でOJT研修</li>
                <br/>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>10月～</h3>
                <li>・正式配属</li>
                <li>・自社採用活動イベントの協力</li>
                <li>・社内イベント企画・実行</li>
                <br/>
                <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>3月～</h3>
                <li>・成果発表</li>
                <br/>
                <li>※メンター制度導入のため各人にメンターがつきます</li>
                <li>※入社後社内チームに所属し、チームリーダーと1on1があります（1ヶ月に1回）</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="04"
          title="応募資格・条件"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>■素直に物事を捉えられる方</li>
                <li>■自分で課題設定ができる方</li>
                <li>■課題に対し工夫して取り組める方</li>
                <li>■信頼関係を築くのが上手な方</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="05"
          title="募集人数"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>若干名</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="06"
          title="勤務形態"
          subtitle=""
          content={
            <div>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務地】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>東京都港区芝浦3丁目17-11天翔田町ビル1002号</li>
                <li>(自社内で開発を行う場合と、顧客先で開発を行う場合があります)</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【勤務時間】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>完全土日祝休み 9:30 ～ 18:30</li>
                <li>（顧客先により異なる場合があります。）</li>
              </ul><br/>
              <h3 style={{ fontSize: "18px", fontWeight: "bold" }}>
                【休日休暇】
              </h3>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 年間休日120日以上</li>
                <li>◇ 夏季休暇</li>
                <li>◇ 年末年始休暇</li>
                <li>◇ 産前産後休暇・育児休暇制度</li>
              </ul><br/>
            </div>
          }
        />
        <DropdownItem
          number="07"
          title="福利厚生"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>◇ 雇用保険</li>
                <li>◇ 厚生年金</li>
                <li>◇ 労災保険</li>
                <li>◇ 健康保険</li>
                <li>◇ 交通費支給あり</li>
                <li>◇ テレワーク・在宅OK</li>
                <li>◇ 時短勤務制度あり</li>
                <li>◇ 資格取得支援（受験料・書籍購入・メンター相談）</li>
                <li>◇ チーム懇親会費用負担</li>
                <li>◇ 技術研修・自己啓発研修等キャリアに必要な研修は申請で受講可能</li>
                <li>◇ フリーワーク制度（社内副業）</li>
              </ul>
            </div>
          }
        />
        <DropdownItem
          number="08"
          title="給与"
          subtitle=""
          content={
            <div>
              <ul style={{ paddingLeft: "20px", lineHeight: "1.6" }}>
                <li>年俸 3,240,000円 ～ 3,600,000円</li>
              </ul>
            </div>
          }
        />
      </DropdownSection>
    </div>
    </ThemeProvider>
  );
};
