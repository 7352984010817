import React, { useState } from "react";

const DropdownItem = ({ title, subtitle, content, number }) => {
  const [isOpen, setIsOpen] = useState(false); // 各項目の開閉状態を管理

  return (
    <div
      style={{
        marginBottom: "16px",
        backgroundColor: "#eef4ff",
        borderRadius: "8px",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.5)",
        overflow: "hidden",
      }}
    >
      <button
        onClick={() => setIsOpen(!isOpen)}
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "rgba(86, 84, 85, 0.1)",
          border: "none",
          padding: "16px",
          fontSize: "18px",
          fontWeight: "bold",
          cursor: "pointer",
          textAlign: "left",
          transition: "background-color 0.3s, transform 0.2s", // スムーズなアニメーション
        }}
        onMouseOver={(e) => {
          e.currentTarget.style.backgroundColor = "#d7e3ff"; // ホバー時の色変更3, 56, 102
        }}
        onMouseOut={(e) => {
          e.currentTarget.style.backgroundColor = "rgba(86, 84, 85, 0.1)"; // 元の色に戻す
        }}
        onMouseDown={(e) => {
          e.currentTarget.style.transform = "scale(0.98)"; // 押下時の縮小効果
        }}
        onMouseUp={(e) => {
          e.currentTarget.style.transform = "scale(1)"; // 離したときに戻す
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between", // 全体を左右に均等配置
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "16px", // 子要素間の隙間
            }}
          >
            <div
              style={{
                fontSize: "18px",
                fontWeight: "bold",
                color: "rgba( 86, 84, 85, 1)",
                minWidth: "40px", // 番号部分の幅を固定
              }}
            >
              ({number})
            </div>
            <div>
              <div
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: "rgba( 86, 84, 85, 1)",
                }}
              >
                {title}
              </div>
              <div
                style={{
                  fontSize: "14px",
                  fontWeight: "normal",
                  color: "rgba( 86, 84, 85, 1)",
                  marginTop: "4px",
                }}
              >
                {subtitle}
              </div>
            </div>
          </div>
          <div
            style={{
              fontSize: "24px",
              color: "rgba( 86, 84, 85, 1)",
            }}
          >
            {isOpen ? "−" : "+"}
          </div>
        </div>
      </button>

      {isOpen && (
        <div
          style={{
            padding: "16px",
            backgroundColor: "#ffffff",
            borderTop: "1px solid #ccc",
            color: "rgba( 86, 84, 85, 1)",
            fontSize: "17px",
            lineHeight: "1.6",
          }}
        >
          {content /* ここに渡されたJSXをそのまま表示 */}
        </div>
      )}
    </div>
  );
};

export default DropdownItem;
