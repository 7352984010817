import React from "react";
import { Box } from "@mui/system"; // MUIのBoxをインポート
import EntryButton from "./EntryButton"; // 応募ボタンのコンポーネントをインポート

const DropdownSection2Button = ({ title, children, buttonText1, buttonUrl1, buttonText2, buttonUrl2, targetLink1, targetLink2}) => {
  const isMobile = window.innerWidth <= 768; // スマホ判定

  return (
    <div
      style={{
        marginBottom: "24px",
        backgroundColor: "#e0e7ff",
        borderRadius: "8px",
        border: "2px solid #4a60e0",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        overflow: "hidden",
      }}
    >
      <Box
        style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor:"#25395E",
                color: "#ffffff",
                border: "none",
                padding: "20px",
                fontSize: isMobile ? "18px" : "20px",
                fontWeight: "bold",
                cursor: "pointer",
                textAlign: "left",
                borderRadius: "8px 8px 0 0",
                transition: "background-color 0.3s, transform 0.2s",
                whiteSpace: "pre-wrap", // ← 改行を反映
                wordBreak: "break-word", // ← 長い単語で折り返し
                overflowWrap: "break-word", // ← さらに折り返しを強制
              }}
      >
        <span>{title}</span>
      </Box>
      {/* 開閉するコンテンツ */}
        <div
          style={{
            padding: "20px",
            backgroundColor: "#ffffff",
            color: "#555",
            fontSize: isMobile ? "11px" : "18px",
            lineHeight: "1.6",
            whiteSpace: "pre-wrap", // ← 改行を反映
            wordBreak: "break-word", // ← 長い単語で折り返し
            overflowWrap: "break-word", // ← さらに折り返しを強制
            display: "block", // ← 確実に適用
          }}
        >
          {children}
          <Box sx={{ marginTop: "20px",
                     display: "flex",
                     flexDirection: isMobile ? "column" : "row", // スマホなら縦並び、PCなら横並び
                     justifyContent: "center"
                    }}>
            {/* 同じタブで開く */}
            <EntryButton text={buttonText1 || "新卒採用情報はこちら"} url={buttonUrl1 || "/recruit/new-graduate-entry"} target={targetLink1} />
            {/* 別タブで開く */}
            <EntryButton text={buttonText2 || "応募する"} url={buttonUrl2 || "https://forms.gle/aYs9yXQ7kgH88G516"} target={targetLink2} />
          </Box>
        </div>
    </div>
  );
};

export default DropdownSection2Button;
