import * as React from 'react';
import '../../../App.css';
import PersonInterview2025 from '../../../components/PersonInterview2025';
import NewEmployee3Top from '../../../image/interviews/new_employee3_top.webp'
import { useMediaQuery } from 'react-responsive';
import { createTheme, ThemeProvider } from '@mui/material/styles';

export default function NewEmployeeInterview6(props) {
  React.useEffect(() => {
    if (props.pageTitle !== undefined && props.pageTitle !== null) {
      document.title = props.pageTitle + `｜コラボテクノ株式会社`;
    }
  });
  const isMobileScreen = useMediaQuery({ query: '(max-width: 760px)' })
  const theme = createTheme({
    typography: {
      fontFamily: `'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'`,
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <PersonInterview2025
        nextUrl={"/recruit/member/interview4"}
        tagStyle={{ float: 'left' }}
        tagText={"INTERVIEW"}
        name={"K.T"}
        entryYear={"2022"}
        title={isMobileScreen ? "個人のスキル次第で\n大きな仕事に挑めるのが、\nエンジニアの魅力。" : "個人のスキル次第で\n大きな仕事に\n挑めるのが、\nエンジニアの魅力。"}
        imageSrcTop={NewEmployee3Top}
        text1={"\n――K.Tさんは就職活動をしていた当時、エンジニア職は頭になかったと聞きました。どうしてエンジニアになったのですか？"}
        text2={"就職活動の初めは食品メーカーを志望していましたが、大手はどこも厳しくて、早々に諦めざるを得ませんでした。このまま食品分野にこだわるべきか再考したときに、自分は食品に限らず大きな企業の大きな仕事をしたいんだと気づき、エンジニアに路線変更しました。エンジニアについてはよく知らないけれど、スキル勝負のイメージがあり、個人の力量次第で大きなプロジェクトにも携われると思ったからです。ゼロからのスタートで不安もありましたが、Youtubeを片っ端から見ながらITやプログラミングを勉強して、なんとか就職できました。「いまタイピングから勉強中です」という困った学生を採用してくれたコラボテクノには、とても感謝しています。"}
        text3={"\n――就活中のタイミングで路線変更とは思い切りましたね。その後、実際にエンジニアとして働いてみて、イメージ通りでしたか？"}
        text4={"入社前のエンジニア像は毎日独りでコツコツとプログラムを書くイメージでしたが、実際は設計書の作成やリリース環境の整備、テストや性能試験など、プログラミング以外の業務が大半を占めていて、少し驚きました。また、フルリモートで人付き合いが少ないと思いきや、チームメンバーとのやりとりなど、コミュニケーションも意外と多く発生します。ITスキルだけでなく、コミュニケーションスキルもそれなりに必要な仕事でしたね。一方、コラボテクノは就活時のイメージ通りというか、むしろ想像以上にフランクで、年齢やキャリアに関係なく気楽に会話できますし、変な忖度や気遣いも不要で居心地がいいです。定例会やチーム会の後など、ちょくちょくリアルで飲みに行く機会もあったりして、楽しくやれています。"}
        text5={"\n――これからエンジニアをめざす方に、新卒から着々とキャリアを積んだ今のK.Tさんならではのアドバイスをください。"}
        text6={"これは実務についてからの話かもしれませんが、まずは周りを頼ることですね。初めてのことはわからなくて当然ですから、どんどん先輩方に聞いてほしいです。そして、聞いた話や進め方をタスクごとにまとめた、自分なりの手順書をつくるといいと思います。この先、久しぶりに同じタスクをするときに思い出しやすいですし、同僚や後輩に教えるときにも役立ちます。何より、自分が積み重ねたスキルの証でもあって、自信につながります。エンジニアは基本的にチームで働きますが、だからこそ一人ひとりの個の力が問われます。コラボテクノは珍しく、エンジニアを使い捨てにせず、個人の成長をとことんサポートしてくれる会社です。自分の将来を自分の力でつかもうとする方に強くおすすめします。"}
      />
    </ThemeProvider>
  );
}