import '../components-css/pageHeaderImage.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as React from 'react';
import { Component } from "react";
import Slider from "react-slick";
import Images from './images';
import pageHeaderTextImg from '../image/EngineeringMyself-b.webp';

export default class PageHeaderImage extends Component {
    componentDidMount() {
        // プリロード用のlink要素を追加
        const link = document.createElement("link");
        link.rel = "preload";
        link.as = "image";
        link.href = process.env.PUBLIC_URL + pageHeaderTextImg;
        document.head.appendChild(link);
    }

    render() {

        function getSettings(rtl) {
            return {
                accessibility: false,
                arrows: false,
                dots: false,
                infinite: true,
                autoplay: true,
                speed: 25000,
                slidesToShow: 5,
                slidesToScroll: 5,
                autoplaySpeed: 100,
                cssEase: "linear",
                pauseOnFocus: false,
                pauseOnHover: false,
                rtl: rtl,
                responsive: [
                    {
                        breakpoint: 1180,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        }
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    }
                ]
            };
        };

        let settingsLtr = getSettings(false);
        let settingsRtl = getSettings(true);

        return (
            <div>
                <div>
                    <Slider {...settingsLtr}>
                        {Images.map((image, index) => {
                            return (
                                <div key={index} className="pageHeaderImage slider" dir="rtl">
                                    <img 
                                        src={process.env.PUBLIC_URL + image} 
                                        alt="topSlider"
                                        width="600"       // 実際の画像の解像度に基づいて設定
                                        height="400"       // 実際の画像の解像度に基づいて設定
                                        style={{
                                            maxWidth: "80%", 
                                            height: "auto", 
                                            display: "block", 
                                            margin: "0 auto",

                                        }}
                                        loading="eager"    // 遅延読み込みを回避
                                        fetchpriority="auto" // 優先度を高く指定
                                    />
                                </div>                            );
                        })}
                    </Slider>
                </div>

                <div className="pageHeaderTextImg">
                    <img
                        src={process.env.PUBLIC_URL + pageHeaderTextImg}
                        alt="topSliderText"
                        width="600"       // 実際の画像の解像度に基づいて設定
                        height="400"       // 実際の画像の解像度に基づいて設定
                        style={{
                            maxWidth: "35%", 
                            height: "auto", 
                            display: "block", 
                            margin: "0 auto"
                        }}
                        loading="eager"    // 遅延読み込みを回避
                        fetchpriority="high" // 優先度を高く指定
                    />
                </div>

                <div>
                    <Slider {...settingsRtl}>
                        {Images.map((image, index) => {
                            return (
                                <div key={index} className="pageHeaderImage slider" dir="rtl">
                                    <img 
                                        src={process.env.PUBLIC_URL + image} 
                                        alt="topSlider"
                                        width="600"       // 実際の画像の解像度に基づいて設定
                                        height="400"       // 実際の画像の解像度に基づいて設定
                                        style={{
                                            maxWidth: "80%", 
                                            height: "auto", 
                                            display: "block", 
                                            margin: "0 auto"
                                        }}
                                        loading="eager"    // 遅延読み込みを回避
                                        fetchpriority="auto" // 優先度を高く指定
                                    />
                                </div>
                            );
                        })}
                    </Slider>
                </div>
            </div>
        );
    }
}
