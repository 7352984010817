import { Box, Typography, ThemeProvider } from "@mui/material";
import * as React from "react";
import { colorTheme } from "../const";
import EmployeesTopImg from "../image/web-photo-02.webp";
import LeaderImg from "../image/IMG_0846.webp";
import ContentsBoxWithTag from "../components/ContentsBoxWithTag";
import ContentsBoxWithBottomAndImage from "../components/ContentsBoxWithBottomAndImage";
import Slider from "../components/Slider";
import Tag from "../components/Tag";
import ScrollToTopIcon from "../components/ScrollToTopIcon";
import { createTheme } from "@mui/material/styles";

export default function Employees(props) {
    React.useEffect(() => {
        if (props.pageTitle) {
            document.title = `${props.pageTitle}｜コラボテクノ株式会社`;
        }
    }, [props.pageTitle]);

    const theme = createTheme({
        typography: {
            fontFamily: "'Noto Sans JP', 'Yu Gothic', 'Meiryo', 'Hiragino Sans', 'Arial', 'sans-serif'",
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box className="App">
                <Box sx={{ backgroundColor: colorTheme.palette.creamGray.main }}>
                    {/* PC版 */}
                    <Box sx={{ display: { xs: "none", sm: "block", md: "block" } }}>
                        <div style={{ position: "relative", width: "100%", padding: "0px 10px 45% 0px" }}>
                            <img
                                src={process.env.PUBLIC_URL + EmployeesTopImg}
                                width="70%"
                                height="100%"
                                alt="トップ画像"
                                style={{ position: "absolute", right: 0 }}
                            />
                            <div
                                style={{
                                    display: "inline-block",
                                    width: "35%",
                                    minWidth: "300px",
                                    height: "83%",
                                    backgroundColor: "white",
                                    position: "absolute",
                                    left: 0,
                                    top: 170,
                                    borderRadius: "0px 20px 20px 0px",
                                    whiteSpace: "pre-line",
                                    margin: "-140px 0 0 0",
                                }}
                            >
                              <Box sx={{ marginTop: "20px" }}> 
                                <Tag tagStyle="left" tagText="INTERVIEW" />
                              </Box>
                                <Box style={{ padding: "10px 5px 0px 50px", textAlign: "left" }}>
                                    <Typography
                                        variant="h5"
                                        sx={{
                                            padding: "2% 0 2% 0",
                                            fontWeight: "bold",
                                            fontSize: {
                                                xs: "15px",
                                                sm: "20px",
                                                md: "25px",
                                                lg: "2.2vw",
                                                xl: "2.4vw",
                                            },
                                            color: colorTheme.palette.navy.main,
                                        }}
                                    >
                                        {"新卒からリーダーまで\n社員インタビュー"}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            padding: { xs:"5px 0px", md:"30px 0px"},
                                            fontSize: {
                                                xs: "10px",
                                                sm: "12px",
                                                md: "15px",
                                                lg: "1.4vw",
                                                xl: "1.6vw",
                                            },
                                            color: colorTheme.palette.navy.main,
                                            lineHeight: "200%",
                                        }}
                                    >
                                        {"コラボテクノで働くエンジニアたちに\n会社や仕事、就職のことをインタビュー。\nまた社内のチームリーダー同士の\n対談も掲載しています。"}
                                    </Typography>
                                </Box>
                            </div>
                        </div>
                    </Box>
                    {/* スマホ版 */}
                    <Box sx={{ display: { xs: "block", sm: "none", md: "none", xl: "none" } }}>
                        <img
                            src={process.env.PUBLIC_URL + EmployeesTopImg}
                            style={{ width: "100%" }}
                            alt="社員のことTop画像"
                        />
                    </Box>
                </Box>
                {/* コンテンツ */}
                <Box sx={{
                    display: { xs: "block", sm: "none", md: "none" },
                    marginTop: "10%",
                    paddingBottom: "5%",
                    color: colorTheme.palette.navy.main,
                    backgroundColor: colorTheme.palette.creamGray.main,
                }}>
                    <ContentsBoxWithTag
                        tagStyle={{ float: "left" }}
                        tagText="INTERVIEW"
                        title="コラボテクノの4つの事業"
                        text="コラボテクノで働くエンジニアたちに、会社や仕事、就職のことをインタビュー。また社内のチームリーダー同士の対談も掲載しています。"
                    />
                </Box>
                {/* セクション: 新卒入社 */}
                <Box sx={{ background: "white", paddingTop: "50px", paddingBottom: "50px" }}>
                    <Typography textAlign="center" sx={{ color: colorTheme.palette.navy.main, fontSize: "2.5vw", fontWeight: "bold" }}>
                        新卒入社
                    </Typography>
                    <Slider category="newEmployee" slidesToShow={3} />
                </Box>

                {/* セクション: ライフワークバランス */}
                <Box sx={{ background: "white", paddingBottom: "50px" }}>
                    <Typography textAlign="center" sx={{ color: colorTheme.palette.navy.main, fontSize: "2.5vw", fontWeight: "bold" }}>
                        ライフワークバランス
                    </Typography>
                    <Slider category="lifeWorkValance" slidesToShow={3} />
                </Box>

                {/* セクション: 異業種転職 */}
                <Box sx={{ background: "white", paddingBottom: "50px" }}>
                    <Typography textAlign="center" sx={{ color: colorTheme.palette.navy.main, fontSize: "2.5vw", fontWeight: "bold" }}>
                        異業種転職
                    </Typography>
                    <Slider category="jobChange" slidesToShow={2} />
                </Box>

                {/* セクション: リーダー */}
                <Box sx={{ background: "white", paddingBottom: "50px" }}>
                    <Typography textAlign="center" sx={{ color: colorTheme.palette.navy.main, fontSize: "2.5vw", fontWeight: "bold" }}>
                        リーダー
                    </Typography>
                    <Slider category="leader" slidesToShow={1} />
                </Box>

                {/* リーダートーク */}
                <Box sx={{ whiteSpace: "pre-line",backgroundColor: colorTheme.palette.creamGray.main, padding: "2% 0" }}>
                    <Tag tagStyle="left" tagText="LEADER TALK" />
                    <ContentsBoxWithBottomAndImage
                        url="/recruit/member/discussion"
                        imageSrc={LeaderImg}
                        tagText="LEADER TALK"
                        buttonText="続きを読む"
                        title2="コラボテクノは新人が働きやすい会社です。"
                        text2="チームリーダー対談"
                        subtext="社内チームリーダーの3名が、会社や現場業務の事から新人の育成まで、フランクに語り合いました。"
                    />
                </Box>
                <ScrollToTopIcon />
            </Box>
        </ThemeProvider>
    );
}
